import React, {useEffect} from "react";
import {Link} from "react-router-dom";

import {exchangeRate} from "../Constants";
import Badge from 'react-bootstrap/Badge';
import {theme} from "../MuiCustomization";
import {ThemeProvider} from "@mui/material";

export default function VehicleListItem({vehicleObj}) {

    const backLink = window.location.pathname + window.location.search;

    return (

        <div key={vehicleObj.id} className="col-md-6 col-lg-4 mb-4">
                    <div className="listing d-block align-items-stretch">
                        <ThemeProvider theme={theme}>
                            <Link
                                key={vehicleObj.id}
                                // Moving to the product page
                                to={`/listing/product?id=${vehicleObj.id}`}
                                state={{article: vehicleObj, backLink: backLink}}>

                                <div className="listing-img me-4 position-relative">
                                    <h5 className="position-absolute top-0 end-0 m-1">
                                        <Badge bg={vehicleObj.stockStatus === 'IN_STOCK' ? "success" : "secondary"}>
                                            {vehicleObj.stockStatus === 'IN_STOCK' ? "В наличии" : "Под заказ"}
                                        </Badge>
                                    </h5>
                                    <img src={vehicleObj.imageLinks[0]} className="img-fluid"/>
                                </div>
                            </Link>
                        </ThemeProvider>

                        <div className="listing-contents">
                            <h3>{vehicleObj.title}</h3>
                            <div className="main-price">
                                <strong>{Math.round(vehicleObj.cost * exchangeRate)} руб.</strong><span
                                className="mx-1">${vehicleObj.cost}</span>
                                {vehicleObj.previousCost !== 0 &&
                                    (<><br/><strike className="pl-2">
                                        <strong>{Math.round(vehicleObj.previousCost * exchangeRate)} руб.</strong><span
                                        className="mx-1">~</span>${vehicleObj.previousCost}
                                    </strike></>)
                                }

                            </div>
                            <div className="d-flex flex-column mb-3 mt-2 border-bottom pb-3">
                                {vehicleObj.searchDescription.map((searchDescriptionObj) => (
                                        <div key={searchDescriptionObj.optionName} className="listing-feature pr-4">
                                            <span className="caption">{searchDescriptionObj.optionName}:</span>
                                            <span className="value">{searchDescriptionObj.value}</span>
                                        </div>
                                    )
                                )}
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <Link
                                        key={vehicleObj.id}
                                        // Moving to the product page
                                        to={`/listing/product?id=${vehicleObj.id}`}
                                        state={{article: vehicleObj, backLink: backLink}}
                                        className="btn btn-red p-2">
                                        <span className="icon-view_day"></span> Подробнее
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

    );
}