import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import ContactsFragment from "./ContactsFragment";

export default function Contacts() {

    useEffect(() => {

        document.title = 'Контакты';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta=document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Контактная информаця');

        let keywordsMeta=document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content", 'Контакты Бел Авто Эксперт');

    }, []);

    return (
        <div className="site-section">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-7 text-center mb-5">
                        <h2>Индивидуальный подход к каждому клиенту</h2>
                        <p>Приезжайте к нам в офис или свяжитесь с нами удобным для вас способом. Обсудим все
                            интересующие
                            вас вопросы.</p>
                    </div>
                </div>
                <div className="row">
                    <div id="contact-list" className="col-lg-4 ml-auto">
                        <ContactsFragment/>
                    </div>
                    <div className="col-lg-8 mb-5">
                        <div className="row align-items-center justify-content-center no-gutters">
                            <div className="col-md-6 mt-3">
                                <img src="/building_img.jpeg" alt="Наш офис" className="img-fluid rounded"/>
                            </div>
                            <div className="col-md-6">
                                <div className="col mt-3">
                                    <img src="/building.JPEG" alt="Общий вид авто площадки"
                                         className="img-fluid rounded"/>
                                </div>
                                <div className="col mt-3">
                                    <img src="/building_img9.jpeg" alt="Общий вид авто площадки"
                                         className="img-fluid rounded"/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    );

}