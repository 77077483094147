import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import {useNavigate} from "react-router-dom"

export default function Blog() {

    const navigate = useNavigate();

    useEffect(() => {

        document.title = 'Блог';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta=document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Блог');

        let keywordsMeta=document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content", 'Блог');

    }, []);

    const articles = [
        {imgLink: 'images/post_1.jpg',
            title: 'Покупка авто в лизинг', date: '04 июня 2023',
            description:'Что такое лизинг, возвратный лизинг. Оценка платежеспособности. Условия для физ. и юр. лиц.'},
        {imgLink: 'images/blog/IMG_2909.jpg',
            title: 'Нулевая растаможка для электрокаров', date: '25 ноября 2023',
            description:'Продление освобождения от уплаты таможенной пошлины на электромобили в странах ЕАЭС до конца 2025 года'},
        {imgLink: 'images/blog/byd-1.png',
            title: 'BYD на рынке электрокаров', date: '21 марта 2024',
            description:'Если вы планируете приобрести электромобиль, то, скорее всего, уже столкнулись со сложностью '
                + 'выбора из-за представленного на рынке множества электромобилей из стран Европы, Китая, США и других.'}
    ];

    return (
        <div className="site-section">
            <div className="container">
                <div className="row">

                    {articles.map((article, index) =>
                        <Article articleLink={'/blog/' + (index+1)} imgLink={article.imgLink}
                                 title={article.title} description={article.description} date={article.date}/>
                    )}

                </div>

            </div>
        </div>

    );

    function Article({ articleLink, imgLink, title, date, description}) {

        return (
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="post-entry-1 h-100">

                    <img src={imgLink} alt="Image" className="img-fluid" onClick={() =>{navigate(articleLink)}}
                         style={{cursor:"pointer"}}/>

                    <div className="post-entry-1-contents">

                        <h2><a href={articleLink}>{title}</a></h2>
                        <span className="meta d-inline-block mb-3">{date}</span>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        );
    }

}