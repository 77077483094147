import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ElectricCarIcon from '@mui/icons-material/ElectricCar';

export default function Article3() {

    useEffect(() => {

        document.title = 'BYD на рынке электрокаров';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta=document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'BYD на рынке электрокаров');

        let keywordsMeta=document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content", 'BYD на рынке электрокаров');

    }, []);

    return (
        <div className="site-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 blog-content">
                        <h2>BYD на рынке электрокаров</h2>

                        <p>Если вы планируете приобрести электромобиль, то, скорее всего, уже столкнулись со сложностью
                            выбора из-за представленного на рынке множества электромобилей из стран Европы, Китая, США и
                            других. Различные модели, надежность и качество машин, технические характеристики и функции,
                            цены и доступность, требования к обслуживанию и ремонту - все это следует учесть для
                            определения самого подходящего варианта в долгосрочной перспективе.
                        </p>
                        <p>Для того, чтобы быть уверенными в надежности и долговечности нового электромобиля,
                            рекомендуется выбирать авто от производителя с надежной репутацией по качеству
                            аккумуляторов. Одним из таких производителей является китайская компания BYD, которая уже
                            стала главным конкурентом Tesla и лидером по продажам электрокаров в мире.
                        </p>
                        <p>Компания изначально занималась производством аккумуляторных батарей для мобильных телефонов,
                            а сегодня является главным игроком в сфере электрокаров. Ее президентом и основателем
                            является миллиардер Ван Чуаньфу, который 30 лет назад изучал и разбирал батареи известных
                            производителей, таких как Sony и Sanyo, чтобы создать аналогичные, но более доступные по
                            цене. По тому же принципу BYD стала производить и автомобили. Для этого компания привлекает
                            опытных дизайнеров и специалистов из крупных автомобильных компаний, таких как Audi, Alfa
                            Romeo, Mercedes и Ferrari. Однако, успех BYD не только в этом. Они самостоятельно производят
                            более 90% компонентов своих автомобилей, включая чипы и аккумуляторы, и контролируют добычу
                            лития и никеля, необходимых для создания батарей. Это позволяет BYD опережать Tesla и стать
                            лидером в мире электрокаров.
                        </p>
                        <p>В 2022 году компания BYD стала широко известной. Тогда за первое полугодие китайская компания
                            продала около 641 000 автомобилей, а Tesla только 546 тысяч. За весь 2022г, разрыв получился
                            ещё значительнее 1,86 млн у китайской компании и 1,31 млн у Tesla. Особенность в том, что
                            BYD производит и электрокары, и гибридные автомобили. Тогда же в 2022 году компания стала
                            продавать свои электрокары в Бразилии. Открылись дилерские центры в Новой Зеландии и
                            Австралии. Было объявлено о планах начала продаж в других стран, таких как Германия.
                            Венгрия, Израиль, Таиланд, Индия. Сегодня уже сотни электромобилей колесят и по городам РБ.
                            В Беларуси и России появилась отличная возможность купить новый китайский электромобиль по
                            очень привлекательной цене. <a href="/listing?filterBrands=BYD"> <span
                                className="icon-arrow-right"></span> Онлайн КАТАЛОГ BYD <span
                                className="icon-arrow-left"></span></a>
                        </p>

                        <img src="/images/blog/byd-2.jpeg" className="img-fluid" alt="Responsive image"/>
                    </div>
                    <div className="shadow-lg col-md-4 ms-lg-5">
                        <h3 className="text-center p-3">Отличные условия для приобретения нового BYD</h3>
                        <div className="d-flex bd-highlight">
                            <div className="service-1 p-2 flex-shrink-1 bd-highlight">
                            <span className="service-1-icon">
                                <ElectricCarIcon/>
                            </span>
                            </div>
                            <div className="p-2 w-100 bd-highlight">
                                <h4>BYD в наличии и под заказ</h4>
                                <h6 className="text-black-50">
                                    <a className="btn btn-red" href="/listing?brands=BYD">Онлайн-КАТАЛОГ</a>
                                </h6>
                            </div>
                        </div>

                        <div className="d-flex bd-highlight">
                            <div className="service-1 p-2 flex-shrink-1 bd-highlight">
                            <span className="service-1-icon">
                                <ImportExportIcon/>
                            </span>
                            </div>
                            <div className="p-2 w-100 bd-highlight">
                                <h3>Traid-in</h3>
                                <h6 className="text-black-50">У нас возможна система трейд ин (обмен старого авто на
                                    новый электромобиль)</h6>
                            </div>
                        </div>

                        <div className="d-flex bd-highlight">
                            <div className="service-1 p-2 flex-shrink-1 bd-highlight">
                            <span className="service-1-icon">
                                <CreditScoreIcon/>
                            </span>
                            </div>
                            <div className="p-2 w-100 bd-highlight">
                                <h3>Лизинг/кредит</h3>
                                <h6 className="text-black-50">Наши компании-партнёры могут подобрать для Вас наиболее
                                    выгодные условия кредита или лизинга для покупки электромобиля. <a href="/loan">Подробнее
                                        об условиях лизинга/кредита.</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}