import React from "react";

export default function SectionHeading({sectionHeadingTitle, sectionHeadingMessage}) {
    return (

        <div className="row">
            <div className="col-lg-7">
                <h2 className="section-heading"><strong>{sectionHeadingTitle}</strong></h2>
                {sectionHeadingMessage && <p className="mb-5">{sectionHeadingMessage}</p>}
            </div>
        </div>
    );
}