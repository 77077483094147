import React, {useEffect, useState} from "react";
import Spinner from 'react-bootstrap/Spinner';
import {theme} from "./MuiCustomization";
import {LinearProgress, ThemeProvider} from "@mui/material";

export default function Filter({filterBrands, onUpdateFilterBrands}) {

    const [brands, setBrands] = useState([]);
    const [brandsLoading, setBrandsLoading] = useState(false);

    useEffect(() => {
        getBrandList();
    }, []);

    function getBrandList() {
        setBrandsLoading(true);
        fetch('/api/brands')
            .then(response => response.json())
            .then(data => {
                setBrands(data);
                setBrandsLoading(false);
            });
    }

    function updateFilterBrands(e) {
        if (e.target.checked) {
            onUpdateFilterBrands([...filterBrands, e.target.value]);
        } else {
            onUpdateFilterBrands(filterBrands.filter((filterBrand) => filterBrand !== e.target.value));
        }
    }

    if (brandsLoading) {
        return <ThemeProvider theme={theme}>
            <LinearProgress color="secondary"/>
        </ThemeProvider>;
    } else {
        return (
            <>
                <hr/>
                {!brands.length
                    ? <span>Нет информации для отображения фильтра по марке авто.</span>
                    : (
                        <div className="mb-4">
                            <a data-bs-toggle="collapse" href="#brandMapBlock"
                               role="button" aria-expanded="false" aria-controls="brandMapBlock">
                                <span className="icon-caret-down"> Фильтровать по марке авто</span>
                            </a>
                            <span className="ml-2">: {filterBrands.join(', ')}</span>
                            <div className="collapse my-2" id="brandMapBlock">
                                <div className="card card-body">
                                    <div className="row mb-5">
                                        {brands.map((brandObj) => (
                                            <div className="col-md-2 col-xs-3">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" id={brandObj.name}
                                                           value={brandObj.name} checked={filterBrands.includes(brandObj.name)}
                                                           onClick={(e) => updateFilterBrands(e)}/>
                                                    <label className="form-check-label"
                                                           htmlFor={brandObj.name}>{brandObj.name} ({brandObj.count})</label>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                }

                <div className="d-flex flex-column mb-5">
                    {/*<div className="">
                    <div className="form-check mb-4">
                        <input className="form-check-input" type="checkbox" value="" id="saleCheckBox"/>
                        <label className="form-check-label" htmlFor="saleCheckBox">
                            Только авто со снижением цены
                        </label>
                    </div>
                </div>*/}
                    <div className="">
                        <p className="text-end">
                            <a className="pe-2 fw-semibold" onClick={()=>onUpdateFilterBrands([])}>Сбросить фильтр</a>
                        </p>
                    </div>
                </div>
            </>
        )
    }

}