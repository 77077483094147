import React, {Fragment, useEffect} from "react";
import ReactGA from "react-ga4";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import {
    breadcrumbNameMap,
    servicePriceAC,
    servicePriceBodyProtect,
    servicePriceDisk,
    servicePriceOil
} from "../Constants";
import SectionHeading from "../SectionHeading";
import RelevantLinkBlock from "../fragments/RelevantLinkBlock";
import PriceTable from "./PriceTable";

export default function ServicePrice() {

    let relevantUrls = ["/service", "/service/tireServicePrice"];

    useEffect(() => {

        document.title = 'Обслуживание и ремонт автомобилей, цены на услуги автосервиса, гарантия качества';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta=document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Широкий спектр высококачественных сервисных услуг для автомобилей всех марок и моделей по доступным ценам. Команда квалифицированных специалистов обеспечит вашему авто надежный ремонт и профессиональный уход.');

        let keywordsMeta=document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content", 'автосервис, СТО в Минске, ремонт автомобилей, техобслуживание, прайс, авторемонт');

    }, []);

    function createData(name, price) {
        return { name, price };
    }

    function columnNames() {
        return ["Услуга", "Стоимость"];
    }

    const servicePriceData = [
        createData('Замена масла в ДВС в л/а', servicePriceOil),
        createData('Замена масла в ДВС микроавтобусов', 'От 55.00 рублей'),
        createData('Замена масла в мосту', 'От 40.00 рублей'),
        createData('Промывка двигателя', 'От 25.00 рублей'),
        createData('С/у защиты двигателя', '15.00 рублей'),
        createData('Осмотр подвески', '35.00 рублей'),
        createData('Замена стоек стабилизатора', 'От 70.00 рублей'),
        createData('Замена ступичного подшипника', 'От 90.00 рублей'),
        createData('Замена рулевого наконечника', 'От 50.00 рублей'),
        createData('Замена тормозных колодок(дисков)', 'От 60.00 рублей'),
        createData('Замена рулевой тяги', 'От 50.00 рублей'),
        createData('Замена втулки амортизатора', 'От 80.00 рублей'),
        createData('Замена пружины', 'От 105.00 рублей'),
        createData('Стоимость нормо-часа', 'От 60.00 рублей'),
        createData('Установка суппорта', 'От 30.00 рублей'),
        createData('Заправка кондиционера масло УФ (100 гр)', servicePriceAC),
        createData('Проточка тормозных дисков', servicePriceDisk),
        createData('Проточка тормозных дисков (джипы, микроавтобусы)', 'От 60.00 рублей'),
        createData('Антикоррозийная обработка кузова', servicePriceBodyProtect),
    ];

    return (
        <div className="site-section">
            <div className="container">

                <Grid container spacing={0}>
                    <Grid xs={12} md={8} lg={9}>
                        <SectionHeading sectionHeadingTitle={"Прайс на сервисные услуги"}
                                        sectionHeadingMessage={<>Мы обеспечиваем нашим клиентам неизменно высокие стандарты качества на все виды оказываемых услуг. <br/>
                                        В нашем автосервисе вы найдете адекватные и доступные цены. На каждую выполненную работу мы предоставляем гарантию. <br/>
                                        Наши сотрудники - высококвалифицированные специалисты с обширным опытом. <br/>
                                        Услуги выполняются оперативно с использованием современного оборудования.</>}/>

                        <PriceTable columnNames={columnNames()} priceData={servicePriceData}/>

                    </Grid>
                    <Grid xs={12} md={4} lg={3} >
                        <RelevantLinkBlock relevantUrls={relevantUrls}/>
                    </Grid>
                </Grid>

            </div>
        </div>

    );

}