import React, {Fragment, useEffect} from "react";
import ReactGA from "react-ga4";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import GarageIcon from '@mui/icons-material/Garage';
import RecommendIcon from '@mui/icons-material/Recommend';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PaymentIcon from '@mui/icons-material/Payment';
import MediationIcon from '@mui/icons-material/Mediation';
import {Grid} from "@mui/material";
import {Link} from "react-router-dom";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import {
    BLOG_3_PAGE_URL,
    LOAN_PAGE_URL,
    VEHICLE_ORDER_LISTING_PAGE_URL,
    VEHICLE_ORDER_PRICING_PAGE_URL
} from "../Constants";
import SectionHeading from "../SectionHeading";
import {NoCrash} from "@mui/icons-material";
import RelevantLinkBlock from "../fragments/RelevantLinkBlock";

export default function VehicleOrderMain() {

    let relevantUrls = ["/vehicleOrder/catalog", "/vehicleOrder/pricing", "/vehicleOrder/listing",
        "/loan", "/blog/3"];

    useEffect(() => {

        document.title = 'Новое Авто под Заказ в Беларуси';

        let descMeta=document.querySelector("meta[name='description']");
        descMeta.setAttribute("content",
            'Новый автомобиль напрямую из Китая по низкой цене. Электромобили, бензиновые, гибриды. Быстрая доставка в Беларусь. '
            + 'Оформление в кредит, лизинг без первоначального взноса. Тел: 688-90-33');

        let keywordsMeta=document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content",
            'купить новый автомобиль, авто из Китая под заказ, китайские автомобили, автозаказ, китайские марки авто, машину купить из Китая, электрокар');

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

    }, []);

    return (
        <div className="site-section bg-light">
            <div className="container">
                <Grid container spacing={0}>
                    <Grid xs={12} md={8} lg={9}>
                        <SectionHeading sectionHeadingTitle={"Авто из Китая"}
                                        sectionHeadingMessage={<Fragment>
                                            Современные автомобили производства КНР в последнее время стали популярными не только в Беларуси и России, но и во многих других странах мира. <br/>
                                            Пришло время новых возможностей для желающих купить новое
                                            <a href="/vehicleOrder/listing"> авто напрямую из Китая под заказ</a>.
                                        </Fragment>}/>
                        <div className="row">
                            <InfoItem icon={<LocalOfferIcon/>}
                                      title={"Привлекательные цены"}
                                      description={<>
                                          Наличие надёжных партнёров в Китае позволяет предлагать нашим клиентам
                                          выгодные цены на покупку выбранного автомобиля.
                                          Мы предоставляем <a href="/vehicleOrder/pricing">детальный разбор</a> всех
                                          компонентов итоговой цены, включая расходы на наши услуги.<br/>
                                          Первоначальный взнос всего 25%.
                                      </>}/>

                            <InfoItem icon={<GarageIcon/>}
                                      title={"Большой выбор авто"}
                                      description={<>
                                          У нас вы найдёте огромный выбор марок, моделей, цветов и комплектаций авто -
                                          некоторые версии создаются или поставляются эксклюзивно для китайского
                                          рынка.
                                          С доступными к заказу марками и моделями автомобилей Вы можете ознакомиться
                                          у нас на сайте.<br/>
                                          <p><Link to="/vehicleOrder/listing">Узнать больше</Link></p>
                                      </>}/>

                            <InfoItem icon={<RecommendIcon/>}
                                      title={"Индивидуальный подход"}
                                      description={<>
                                          При заказе автомобиля из Китая, можно добавить дополнительные опции по своему
                                          желанию,
                                          что сделает Ваш автомобиль уникальным, полностью отвечающим Вашим
                                          потребностям.
                                          Наши менеджеры всегда на связи, рады ответить на любые ваши вопросы и помочь
                                          с выбором автомобиля.
                                      </>}/>

                            <InfoItem icon={<CreditScoreIcon/>}
                                      title={"Выгодный кредит/лизинг"}
                                      description={<>
                                          Для Вас доступны самые лучшие программы оформления авто
                                          в кредит/лизинг от наших банков партнёров и лизинговых компаний,
                                          в том числе без первоначального взноса.
                                          <p><a href="/loan">Наши партнеры</a></p>
                                      </>}/>

                            <InfoItem icon={<DateRangeIcon/>}
                                      title={"Быстрая и безопасная доставка"}
                                      description={<>
                                          Мы гарантируем Вам оперативную и безопасную доставку автомобилей из Китая в
                                          Беларусь и Россию на прозрачных и понятных условиях.
                                          Сроки поставки автомобиля <strong>(до 1,5 месяцев), по возможности более сжатые.</strong>
                                          </>}/>
                            <InfoItem icon={<SupportAgentIcon/>}
                                      title={"Доставка в ваш город"}
                                      description={<>
                                          На всех этапах сопровождением сделки и транспортировкой будут заниматься
                                          настоящие профессионалы.
                                      </>}/>
                            <InfoItem icon={<GarageIcon/>}
                                      title={"Доп тех обслуживание"}
                                      description={<>
                                          Вы можете заказать дополнительные услуги по антикорозийной обработке,
                                          шиномонтажу, руссификации, установке сим карт, защите бамперов/днища и др.
                                          При этом Вам не придется тратить время на дорогу и очереди,
                                          т к все работы выполняются на СТО в нашем автосалоне.
                                      </>}/>
                            <InfoItem icon={<LoyaltyIcon/>}
                                      title={"Бонусы"}
                                      description={<Fragment>
                                          От нашей компании всем нашим клиентам антикоррозийная обработка днища в
                                          подарок.
                                      </Fragment>}/>
                            <InfoItem icon={<CarRepairIcon/>}
                                      title={"Гарантийное обслуживание"}
                                      description={<>
                                          Вы получаете всю необходимую информацию об условиях технического
                                          обслуживания,
                                          ближайших дилерских центрах и гарантийных обязательствах производителя на
                                          территориях РБ и РФ.
                                      </>}/>
                        </div>

                        <h4>Шаги к приобретению автомобиля</h4>
                        <div className="row">
                            <div className="col-lg-8 mb-5">
                                <Timeline position="alternate">

                                    <OrderStepItem stepNumber={1} icon={<PageviewIcon />} title={"Подбор авто"}
                                                   description={<>
                                                       Выберите модель автомобиля в нашем каталоге или запросите свой вариант.<br/>
                                                       Если есть необходимость, наши менеджеры проконсультируют Вас и помогут определиться с выбором.
                                                   </>}/>

                                    <OrderStepItem stepNumber={2} icon={<AssignmentTurnedInIcon />} title={"Договор"}
                                                   description={<>Заключаем с Вами договор купли-продажи.</>}/>

                                    <OrderStepItem stepNumber={3} icon={<PaymentIcon />} title={"Предоплата"}
                                                   description={<>Предоплата составляет всего 25% от стоимости автомобиля.</>}/>

                                    <OrderStepItem stepNumber={4} icon={<MediationIcon />} title={"Доставка"}
                                                   description={<>Сроки поставки автомобиля (до 1,5 месяцев), по возможности доставляем в течение месяца.</>}/>

                                    <OrderStepItem stepNumber={5} icon={<PaymentIcon />} title={"Оплата по договору"}
                                                   description={<>Платеж оставшейся суммы - 75%.</>}/>

                                    <OrderStepItem stepNumber={6} icon={<CarRepairIcon />} title={"Доп тех обслуживание"}
                                                   description={<>Дополнительные услуги для Вашего авто, если необходимо (шиномонтаж, антикорозийная обработка,
                                                       руссификация, установка сим карт, защита бамперов/днища и др.)</>}/>

                                    <OrderStepItem stepNumber={7} icon={<NoCrash />} title={"Завершение сделки"}
                                                   description={<>Вы забираете автомобиль в нашем автоцентре или заказываете доставку в Ваш населенный пункт.</>}/>

                                </Timeline>
                            </div>
                            <div className="col-lg-4 ml-auto">

                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} md={4} lg={3} >
                        <RelevantLinkBlock relevantUrls={relevantUrls}/>
                    </Grid>
                </Grid>
            </div>
        </div>

    );

    function OrderStepItem({stepNumber, icon, title, description}) {
        return <TimelineItem>
            <TimelineOppositeContent
                sx={{m: 'auto 0'}}
                align="right"
                variant="body2"
                color="text.secondary"
            >
                {stepNumber}.
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector/>
                <TimelineDot color="primary">
                    {icon}
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent sx={{py: '12px', px: 2}}>
                <Typography variant="h6" component="span">
                    {title}
                </Typography>
                <Typography>
                    {description}
                </Typography>
            </TimelineContent>
        </TimelineItem>
    }

    /**
     *
     * @param icon the icon tag
     * @param title the title
     * @param description can be text, variable or react fragment tag
     * @returns {JSX.Element}
     * @constructor
     */
    function InfoItem({icon, title, description}) {
        return <div className="col-lg-4 mb-3 ps-0">
            <div className="service-1 dark">
                <span className="service-1-icon">
                    {icon}
                </span>
                <div className="service-1-contents">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </div>
        </div>;
    }

}