import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import PlaceIcon from '@mui/icons-material/Place';
import VideocamIcon from '@mui/icons-material/Videocam';
import PeopleIcon from '@mui/icons-material/People';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {commissionCost} from "../Constants";

export default function Commission() {

    useEffect(() => {

        document.title = 'Помощь в продаже авто';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Помощь в продаже авто');

        let keywordsMeta = document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content", 'Помощь в продаже авто, автохаус, продать машину, авторынок ждановичи');

    }, []);

    return (
        <div className="site-section">
            <div className="container">
                <div id="ourService" className="site-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <h2 className="section-heading"><strong>Наши услуги</strong></h2>
                                <p className="mb-3">При обращении, нашим клиентам доступны бесплатно следующее
                                    услуги:</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 mb-3">
                                <div className="service-1 dark">
                                    <span className="service-1-icon">
                                        <CreditScoreIcon/>
                                    </span>
                                    <div className="service-1-contents">
                                        <h3>Кредит/Лизинг</h3>
                                        <p>Мы помогаем нашим покупателям получить и оформить лизинг на льготных условиях
                                            по госпрограмме, автокредит, кредит на потребительские нужды. </p>
                                        <p className="mb-0"><a href="/loan">Узнать больше</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <div className="service-1 dark">
                                    <span className="service-1-icon">
                                        <ImportExportIcon/>
                                    </span>
                                    <div className="service-1-contents">
                                        <h3>Трейдин</h3>
                                        <p>Принимаем автомобили от 2000 года выпуска в зачёт. Автомобили более раннего
                                            года
                                            выпуска мы можем рассмотреть для возможности оставить у нас для дальнейшей
                                            реализации.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <div className="service-1 dark">
                                    <span className="service-1-icon">
                                        <SupportAgentIcon/>
                                    </span>
                                    <div className="service-1-contents">
                                        <h3>Консультация</h3>
                                        <p>При необходимости наши опытные эксперты по продаже авто дадут подробные
                                                рекомендации, предоставят консультацию.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <div className="service-1 dark">
                                    <span className="service-1-icon">
                                        <LocalCarWashIcon/>
                                    </span>
                                    <div className="service-1-contents">
                                        <h3>Подготовка авто</h3>
                                        <p>Организация предпродажной подготовки, начиная от химчистки и полировки,
                                            заканчивая
                                            подкраской, мелким ремонтом и устранением технических проблем.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <div className="service-1 dark">
                                    <span className="service-1-icon">
                                        <PlaceIcon/>
                                    </span>
                                    <div className="service-1-contents">
                                        <h3>Размещение</h3>
                                        <p>Охраняемая площадка с видеонаблюдением в Минске по
                                            <a href="https://yandex.by/maps/-/CCUw58HHxC">ул. Тимирязева 119, корпус
                                                1</a>
                                            (180 машиномест, авторынок "Ждановичи",
                                            напротив МРЭО, ГАИ сверка номеров"). Удобный подъезд, мойка
                                            самообслуживания,
                                            шиномонтаж, большой гостевой паркинг.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <div className="service-1 dark">
                                    <span className="service-1-icon">
                                        <VideocamIcon/>
                                    </span>
                                    <div className="service-1-contents">
                                        <h3>Видео/фото обзоры</h3>
                                        <p>Мы создаем детальные видеообзоры для каждого автомобиля,
                                            и размещаем их на различных онлайн платформах вместе с фотографиями и
                                            описанием.
                                            Даже самые малозаинтересованные покупатели не смогут не обратить внимание на
                                            Ваш автомобиль ;)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <div className="service-1 dark">
                                    <span className="service-1-icon">
                                        <PeopleIcon/>
                                    </span>
                                    <div className="service-1-contents">
                                        <h3>Реализация</h3>
                                        <p>Находим и общаемся с потенциальными покупателями Вашего авто, экономя Ваше
                                            время. Все сделки официально документированы и абсолютно прозрачны.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div id="how_it_works" className="site-section">
                    <div className="container">
                        <h2 className="section-heading"><strong>Как проходит сделка</strong></h2>
                        <p className="mb-3">Чтобы поручить нам авто для дальнейшей реализации необходимо:</p>

                        <div className="row mb-3">
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="step">
                                    <span>1</span>
                                    <div className="step-inner">
                                        <span className="number">01.</span>
                                        <h3>Визит или звонок</h3>
                                        <p><a href="/contact#contact-list">Свяжитесь с нами удобным для Вас способом</a> или
                                            приезжайте к нам в офис по адресу в Минске <a href="https://yandex.by/maps/-/CDaHjJ-M"> ул.
                                                Тимирязева 119, корпус 1</a> для уточнения всех интересующих Вас вопросов.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="step">
                                    <span>2</span>
                                    <div className="step-inner">
                                        <span className="number">02.</span>
                                        <h3>Осмотр машины</h3>
                                        <p>Если вы находитесь не в Минске, есть возможность выезда нашего
                                            специалиста к Вам, оформления договора на месте и доставку Вашего авто на
                                            нашу
                                            выстовочную автоплощадку.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="step">
                                    <span>3</span>
                                    <div className="step-inner">
                                        <span className="number">03.</span>
                                        <h3>Согласование стоимости</h3>
                                        <p>Продадим Ваше авто по наиболее подходящей для Вас цене. В процессе
                                            реализации,
                                            любую предлагаемую покупателем цену мы обсудим и согласуем с Вами.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="step">
                                    <span>4</span>
                                    <div className="step-inner">
                                        <span className="number">04.</span>
                                        <h3>Подготовка документов</h3>
                                        <p>Все сделки проходят максимально прозрачно, поэтому мы составляем акт осмотра,
                                            акт
                                            приема-передачи,
                                            а также заключаем с Вами договор, где прописываем все условия для обеих
                                            сторон.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="step">
                                    <span>5</span>
                                    <div className="step-inner">
                                        <span className="number">05.</span>
                                        <h3>Подготовка авто</h3>
                                        <p>Организация предпродажной подготовки, начиная от химчистки и полировки,
                                            заканчивая подкраской, мелким ремонтом и устранением технических
                                            проблем.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="step">
                                    <span>6</span>
                                    <div className="step-inner">
                                        <span className="number">06.</span>
                                        <h3>Реализация</h3>
                                        <p>Мы активно занимаемся рекламой и общаемся с потенциальными покупателями.
                                            В случае необходимости помогаем нашим покупателям с оформлением <a href="/loan">кредита/лизинга</a> на
                                            покупку авто. <br/>
                                            Комиссия фиксированная и составляет {commissionCost} бел. руб.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="step">
                                    <span>7</span>
                                    <div className="step-inner">
                                        <span className="number">07.</span>
                                        <h3>Завершение сделки</h3>
                                        <p>Вы (или доверенное лицо) гарантированно получаете договоренную ранее сумму на
                                            руки
                                            либо на расчетный лицевой счёт.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div id="ourService" className="site-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 ml-auto">
                                <h2 className="section-heading"><strong>Факты о нашей автостоянке</strong></h2>

                                <p>Автостоянка БелАвтоЭксперт имеет выгодное проходное расположение,
                                    так как находится напротив МРЭО ГАИ г Минска и Минской области, где снимают с учета
                                    автомобили автовладельцы из всей Беларуси.</p>

                                <p>Удобный подъезд к автоплощадке, большой гостевой паркинг.
                                    На территории имеется комплексная мойка, мойка самообслуживания, круглосуточный
                                    шиномонтаж.</p>

                                <p>Охраняемая территория, система видеонаблюдения. По запросу предоставляем отчет с
                                    камер
                                    видеонаблюдения.</p>

                                <p>Наши эксперты имеют опыт &gt;15 лет по реализации авто.
                                    Компетентные и внимательные менеджеры предоставят консультацию и ответят на все
                                    интересующие
                                    вас вопросы. </p>

                                <p>Ежедневно на нашей автоплощадке 3-5 машин находят своих покупателей.</p>

                                <p> Мы помогаем Нашим покупателям получить и оформить лизинг на льготных условиях по
                                    госпрограмме (возможность получения лизинга до 10 лет в белорусских рублях по ставке
                                    рефинансирования НБ РБ, плюс 2.5 п.п. с первоначальным взносом всего 10 процентов),
                                    автокредит, кредит на потребительские нужды.</p>

                                <p>Принимаем автомобили в зачёт (трэйд-ин). К каждому нашему клиенту у нас
                                    индивидуальный
                                    подход. При необходимости, дадим рекомендации, предоставим консультацию.</p>

                                <p>Оказываем услуги по организации предпродажной подготовки, начиная от химчистки и
                                    полировки,
                                    заканчивая подкраской, мелким ремонтом и устранением некоторых технических
                                    проблем.</p>

                                <p>На каждое авто у нас делается подробный видеообзор, который выкладывается вместе c
                                    фото и
                                    описанием авто на популярных интернет ресурсах.</p>

                                <p>Автосалон БелАвтоЭксперт может вместить до 180 автомобилей. Успейте разместить ваше
                                    авто,
                                    пока еще есть свободные машиноместа.</p>

                                <p>Будем рады взаимовыгодному сотрудничеству! Продадим ваше авто по наиболее выгодной
                                    для вас
                                    цене. В отличие от большинства автосалонов РБ у нас, вне зависимости от стоимости
                                    авто,
                                    комиссия в случае реализации авто фиксированная и составляет всего {commissionCost} рублей НБ
                                    РБ.
                                    Указанная комиссия будет входить в общую стоимость автомобиля и существенно на нее
                                    не
                                    повлияет. Любую предлагаемую покупателем цену мы обсудим и согласуем с вами. Все,
                                    заключаемые с нашей организацией, сделки законны, официально документированы и
                                    абсолютно
                                    прозрачны.</p>
                            </div>
                            <div className="col-lg-6 mb-5 mb-lg-0">
                                <img src="/hero_1.jpg" alt="Image" className="img-fluid rounded"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}