import '../App.css';
import CallNowBlock from "./CallNowBlock";
import Footer from "./Footer";
import Head from "./Head";

import {BrowserRouter, Route, Routes} from "react-router-dom";
import ScrollToTop from "./Util";
import VehicleList from "./listing/VehicleList";
import VehicleView from "./listing/VehicleView";
import Main from "./Main";
import VehicleOrderMain from "./vehicleorder/VehicleOrderMain";
import Loan from "./Loan";
import Service from "./servise/Service";
import Commission from "./comission/Commission";
import CommisssionQuestions from "./comission/CommissionQuestions";
import Contacts from "./Contacts";
import Address from "./contact/Address";
import {theme} from "./MuiCustomization";
import {ThemeProvider} from "@mui/material";
import Blog from "./blog/Blog";
import Article1 from "./blog/Article1";
import Article2 from "./blog/Article2";
import Article3 from "./blog/Article3";
import * as React from "react";
import PageBreadcrumbs from "./PageBreadcrumbs";
import VehicleOrderListing from "./vehicleorder/VehicleOrderListing";
import ReactGA from "react-ga4";
import VehicleOrderPricingInfo from "./vehicleorder/VehicleOrderPricingInfo";
import ServicePrice from "./servise/ServicePrice";
import TirePrice from "./servise/TirePrice";
import VehicleOrderList from "./vehicleorder/VehicleOrderList";

export default function App() {

    ReactGA.initialize("G-9HG84DY6BL");

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Head/>
                <PageBreadcrumbs/>
                <ScrollToTop />
                {/* Router functionality */}
                <Routes>
                    <Route path='/' element={<Main/>} />

                    <Route path='/listing' element={<VehicleList/>} />
                    <Route path="/listing/product" element={<VehicleView/>} />

                    <Route path="/vehicleOrder" element={<VehicleOrderMain/>} />
                    <Route path="/vehicleOrder/catalog" element={<VehicleOrderList/>} />
                    <Route path="/vehicleOrder/listing" element={<VehicleOrderListing/>} />
                    <Route path="/vehicleOrder/pricing" element={<VehicleOrderPricingInfo/>} />

                    <Route path="/loan" element={<Loan/>} />

                    <Route path="/service" element={<Service/>} />
                    <Route path="/service/servicePrice" element={<ServicePrice/>} />
                    <Route path="/service/tireServicePrice" element={<TirePrice/>} />

                    <Route path="/commission" element={<Commission/>} />
                    <Route path="/commissionQuestions" element={<CommisssionQuestions/>} />
                    <Route path="/contacts" element={<Contacts/>} />
                    <Route path="/location" element={<Address/>} />

                    <Route path="/blog" element={<Blog/>} />
                    <Route path="/blog/1" element={<Article1/>} />
                    <Route path="/blog/2" element={<Article2/>} />
                    <Route path="/blog/3" element={<Article3/>} />

                </Routes>

            </BrowserRouter>

            <CallNowBlock/>
            <Footer/>
        </ThemeProvider>
    );

}


