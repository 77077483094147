import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import {Grid} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import VerifiedIcon from '@mui/icons-material/Verified';
import TrainIcon from '@mui/icons-material/Train';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import {breadcrumbNameMap} from "../Constants";
import RelevantLinkBlock from "../fragments/RelevantLinkBlock";

export default function VehicleOrderPricingInfo() {

    let relevantUrls = ["/vehicleOrder", "/vehicleOrder/catalog", "/vehicleOrder/listing",
        "/loan", "/blog/3"];

    useEffect(() => {

        document.title = 'Выгодные цены на автомобили. Бесплатные консультации о покупке и доставке машин';

        let descMeta= document.querySelector("meta[name='description']");
        descMeta.setAttribute("content",
            'Как формируется стоимость ввозимого автомобиля из Китая в Беларусь. Детальный разбор итоговой цены на Китайские машины.');

        let keywordsMeta= document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content",
            'Цена автомобиля в Минске, доставка авто из Китая в Беларусь, купить новую машину в Беларуси');

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

    }, []);

    return (
        <div className="site-section bg-light">
            <div className="container">
                <Grid container spacing={0}>
                    <Grid xs={12} md={8} lg={9}>
                        <h2 className="section-heading"><strong>Стоимость авто</strong></h2>
                        <p className="mb-5 me-2">
                            Мы предлагаем <a href="/vehicleOrder">помощь в подборе, бесплатную консультацию, а также быструю и безопасную
                                доставку авто из Китая в Беларусь!</a> <br/>
                            Работаем без посредников, и это позволяет нам предлагать своим клиентам лучшие цены
                            на китайские автомобили.
                        </p>

                        <Grid container spacing={0}>
                            <Grid item >
                                <h5>Итоговая цена автомобиля с доставкой в Минск включает:</h5>

                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <ImportExportIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Подготовка и оформление необходимых документов для экспорта автомобиля их Китая"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <PersonPinCircleIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Услуги экспедитора"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <TrainIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Доставка автомобиля ЖД контейнером, полная страховка груза уже включена в стоимость доставки"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <FactCheckIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Услуги логистической компании: выгрузка из контейнера, транспортировка в зоне таможенного досмотра, хранение на складе таможни"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <VerifiedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Регистрация ЗОЕТС (заключение об оценке единичного ТС) и ЭПТС (электронный паспорт ТС)"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <LocalCarWashIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Комплексная мойка автомобиля"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <LoyaltyIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="В подарок от нашей компании антикоррозийная обработка днища"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <HowToRegIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Полный пакет документов, которые необходимы для регистрации в ГАИ"/>
                                    </ListItem>
                                </List>

                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid xs={12} md={4} lg={3} >
                        <RelevantLinkBlock relevantUrls={relevantUrls}/>
                    </Grid>
                </Grid>
            </div>
        </div>

    );

}