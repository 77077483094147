import React, {useEffect, useState} from "react";
import {replaceJSX, useUrlId} from "../Util";
import Carousel from 'react-bootstrap/Carousel';
import Badge from "react-bootstrap/Badge";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import SectionHeading from "../SectionHeading";
import {BsArrowLeftCircleFill} from "react-icons/bs";
import {PiInstagramLogoThin} from "react-icons/pi";
import {exchangeRate} from "../Constants";
import Spinner from "react-bootstrap/Spinner";
import ReactGA from "react-ga4";
import Link from "@mui/material/Link";
import {IconButton} from "@mui/material";
import Button from "@mui/material/Button";

export default function VehicleView() {

    const {state} = useLocation()
    const {id} = useUrlId();
    const [article, setArticle] = useState(null);
    const [articleVideo, setArticleVideo] = useState(null);
    const [articleLoading, setArticleLoading] = useState(true);

    useEffect(() => {

        if (state && state.article) {
            setArticle(state.article);
            document.title = state.article.title;
        } else {
            fetch(`/api/cars/${id}`)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setArticle(data);
                    document.title = data.title;
                })

        }
        setArticleLoading(false);

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

    }, []);

    useEffect(() => {
        if (article) {
            updateArticleVideo();
        }
    }, []);

    function updateArticleVideo() {
        if (article && !article.videoLink) {
            fetch(`/api/video/${id}`)
                .then(response => response.json())
                .then(data => {
                    setArticleVideo(data.mediaUrl);
                });
        }
    }

    function LinkRouter(props) {
        return <Link {...props} component={RouterLink}/>;
    }

    const navigate = useNavigate();

    return (
        <div className="site-section">
            <div className="container">
                {articleLoading ? <Spinner animation="border" size="sm" className="me-1" role="status">
                        <span className="visually-hidden">Загружаем информацию...</span>
                    </Spinner>
                    :

                    (!article ? <span>"Нет информации для просмотра"</span>
                            :
                            <>
                                {/*Go back*/}
                                <div className="row mb-4">
                                    <div className="col text-right">
                                        <div className="d-flex justify-content-end">
                                            <Link onClick={() => navigate(state.article ? state.backLink : "/listing")} >
                                                <BsArrowLeftCircleFill/> к списку авто
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <SectionHeading sectionHeadingTitle={article.title}/>
                                <small className="fw-lighter fst-italic">VIN {article.id}</small>

                                {/* Price */}
                                <div className="main-price fs-5">
                                    {/* Actual cost */}
                                    <strong>{Math.round(article.cost * exchangeRate)} руб.</strong><span
                                    className="mx-1">${article.cost}</span>

                                    {/* Previous cost */}
                                    {article.previousCost !== 0 &&
                                        (<small className="me-2"> <strike className="pl-2">
                                            <strong>{Math.round(article.previousCost * exchangeRate)} руб.</strong><span
                                            className="mx-1">~</span>${article.previousCost}
                                        </strike></small>)
                                    }
                                </div>

                                <div className="container-fluid py-5">
                                    <div className="row">
                                        {/* Photo & Stock*/}
                                        <div className="col-lg-6 position-relative">

                                            <Carousel slide={false}>
                                                {article.imageLinks.map((imageLink, index) => (
                                                    <Carousel.Item>
                                                        <img className="d-block w-100" src={imageLink}
                                                             alt={"Изображение " + index}/>
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                            <div className="position-absolute top-0 end-0 fs-4 my-1 mx-3">
                                                <Badge
                                                    bg={article.stockStatus === 'IN_STOCK' ? "success" : "secondary"}>
                                                    {article.stockStatus === 'IN_STOCK' ? "В наличии" : "Под заказ"}
                                                </Badge>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            {/* Short Description */}
                                            <p className="mb-4 lh-lg shadow-lg p-3 mb-5 rounded">
                                                {article.shortDescription}
                                            </p>

                                            {/*Tabs*/}
                                            <div className="row mt-5">
                                                <div className="col">
                                                    <div className="nav nav-tabs justify-content-center border-secondary mb-4">
                                                        <button className="nav-link active" id="tab-1" data-bs-toggle="tab"
                                                                data-bs-target="#tab-pane-1" type="button" role="tab"
                                                                aria-controls="tab-1-pane" aria-selected="true">Основные
                                                            Характеристики
                                                        </button>
                                                        <button className="nav-link" id="tab-2" data-bs-toggle="tab"
                                                                data-bs-target="#tab-pane-2" type="button" role="tab"
                                                                aria-controls="tab-2-pane" aria-selected="false">Технические
                                                            характеристики
                                                        </button>
                                                        <button className="nav-link" id="tab-3" data-bs-toggle="tab"
                                                                data-bs-target="#tab-pane-3" type="button" role="tab"
                                                                aria-controls="tab-3-pane" aria-selected="false">Опции
                                                        </button>
                                                        <button className="nav-link" id="tab-4" data-bs-toggle="tab"
                                                                data-bs-target="#tab-pane-4" type="button" role="tab"
                                                                aria-controls="tab-4-pane" aria-selected="false">Видеообзор
                                                        </button>
                                                    </div>
                                                    <div className="tab-content">
                                                        <div className="tab-pane fade show active" id="tab-pane-1">
                                                            <h4 className="mb-3">Основные Характеристики</h4>
                                                            <div className="d-flex flex-column mb-3 mt-2 border-bottom pb-3">
                                                                {article.searchDescription.map((searchDescriptionObj) => (
                                                                        <div key={searchDescriptionObj.optionName}
                                                                             className="listing-feature pr-4">
                                                                <span
                                                                    className="caption">{searchDescriptionObj.optionName}: </span>
                                                                            <span className="value">{searchDescriptionObj.value}</span>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="tab-pane-2">
                                                            <h4 className="mb-3">Технические характеристики</h4>
                                                            {replaceJSX(article.description, "\n", <br/>)}
                                                        </div>
                                                        <div className="tab-pane fade" id="tab-pane-3">
                                                            <h4 className="mb-3">Опции</h4>
                                                            <ul className="list-group list-group-flush">
                                                                {article.options.map((option) => (
                                                                        <li className="list-group-item px-0">
                                                                            {option}
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        </div>
                                                        <div className="tab-pane fade" id="tab-pane-4">
                                                            <h4 className="mb-3">Видеообзор</h4>
                                                            {/* Video */}
                                                            {(!article.videoLink && !articleVideo) &&
                                                                <span>Нет информации для просмотра.</span>
                                                            }
                                                            {(!article.videoLink && articleVideo) &&
                                                                <div className="ratio ratio-16x9 mt-5">
                                                                    <iframe src={articleVideo.mediaUrl}></iframe>
                                                                </div>
                                                            }
                                                            {article.videoLink &&
                                                                <div className="ratio ratio-16x9 mt-5">
                                                                    <iframe width="560" height="315"
                                                                            src={article.videoLink}
                                                                            title="Видео плейер" frameBorder="0"
                                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                            allowFullScreen></iframe>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                {/*Go back*/}
                                <div className="row mb-4">
                                    <div className="col text-right">
                                        <div className="d-flex justify-content-end">
                                            <a className="text-lowercase" onClick={() => navigate(state.article ? state.backLink : "/listing")}>
                                                <BsArrowLeftCircleFill/> к списку авто
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col text-right">
                                        <div className="d-flex justify-content-end">
                                            <a className="text-muted" href={article.permalink}>
                                                <PiInstagramLogoThin/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </>
                    )
                }
            </div>
        </div>
    );
}