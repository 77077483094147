import React, {useEffect} from "react";
import {alpha, Grid, Paper, Stack, styled, useMediaQuery} from "@mui/material";
import DirectionsCarFilledTwoToneIcon from "@mui/icons-material/DirectionsCarFilledTwoTone";
import CarRentalTwoToneIcon from "@mui/icons-material/CarRentalTwoTone";
import CreditScoreTwoToneIcon from "@mui/icons-material/CreditScoreTwoTone";
import EmojiTransportationTwoToneIcon from "@mui/icons-material/EmojiTransportationTwoTone";
import PhoneIcon from '@mui/icons-material/Phone';
import NavigationIcon from '@mui/icons-material/Navigation';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import {Link} from "react-router-dom";
import ReactGA from "react-ga4";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import {theme} from "./MuiCustomization";

export default function Main() {

    useEffect(() => {

        document.title = 'Продать или купить автомобиль в Минске | СТО, обслуживание автомобилей';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Большой выбор авто новых и б/у в автосалоне БелАвтоЭксперт. Заказать авто из Китая. Автосервис, автомагазин');

        let keywordsMeta = document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content", 'автосалон Минск, продажа автомобилей, купить новую или б/у машину, цены на авто, пригнать из Китая');

    }, []);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

    const DemoPaper = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        ...theme.typography.body2,
        backgroundColor: alpha(theme.palette.background.paper, 0.35),

        cursor: 'pointer',
        fontSize: '16px'
    }));

    return (
        <>
            <div className="hero pb-4" style={{backgroundImage: "url('images/hero_1_a.jpg')"}}>

                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-10">

                            <div className="row mb-3">
                                <div className="col-lg-7 intro">

                                    <h1>Автосалон</h1>
                                    <h6>
                                        <a className="text-black" href="tel:6889033"><PhoneIcon color="primary" /> 688-90-33</a>
                                        <a className="text-black ms-4" href="yandexnavi://build_route_on_map?lat_to=53.931451&amp;lon_to=27.465532">
                                            <NavigationIcon color="primary" /> Тимирязева 119/1
                                        </a>
                                    </h6>

                                </div>
                            </div>

                            <Stack direction="column" spacing={2}>
                                <DemoPaper component={Link} to='/listing' variant="elevation" elevation={24}>
                                    <DirectionsCarFilledTwoToneIcon color="primary"/>
                                    &nbsp;Продажа новых автомобилей, электромобилей и гибридов, а также автомобилей с пробегом
                                </DemoPaper>
                                <DemoPaper component={Link} to='/vehicleOrder' variant="elevation" elevation={24}>
                                    <CarRentalTwoToneIcon color="primary"/>
                                    &nbsp;Доставка новых авто напрямую из Китая под заказ
                                </DemoPaper>
                                <DemoPaper component={Link} to='/loan' variant="elevation" elevation={24}>
                                    <CreditScoreTwoToneIcon color="primary"/>
                                    &nbsp;Помощь в подборе кредитных и лизинговых программ на покупку авто совместно с банками-партнерами и лизинговыми компаниями
                                </DemoPaper>
                                <DemoPaper component={Link} to='/commission' variant="elevation" elevation={24}>
                                    <EmojiTransportationTwoToneIcon color="primary"/>
                                    &nbsp;Помощь в продаже подержанных автомобилей / комиссия / обмен (trade-in)
                                </DemoPaper>
                                <DemoPaper component={Link} to='/service' variant="elevation" elevation={24}>
                                    <CarRepairIcon color="primary"/>
                                    &nbsp;СТО. Сервисное обслуживание автомобилей / экспресс-замена масла / антикоррозийная обработка кузова / проточка тормозных дисков / заправка кондиционеров / детейлинг и др.
                                </DemoPaper>
                                <DemoPaper component={Link} to='/service' variant="elevation" elevation={24}>
                                    <TireRepairIcon color="primary"/>
                                    &nbsp;Шиномонтаж / хранение шин
                                </DemoPaper>
                                <DemoPaper component={Link} to='/service' variant="elevation" elevation={24}>
                                    <SettingsSuggestIcon color="primary"/>
                                    &nbsp;Розничная и оптовая продажа запчастей, технических жидкостей, и аксессуаров для автомобилей
                                </DemoPaper>
                            </Stack>

                        </div>
                    </div>
                </div>
            </div>

            <div className="site-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-10">
                            <h3 className="mb-4 section-heading"><strong>Об Автосалоне</strong></h3>
                            <div className="row mb-3">
                                <div className="col-lg-4 text-center order-lg-2">
                                    <img src="/building_main.JPEG" alt="Автосалон" className="img-fluid mb-2" />
                                    <img src="/cars.JPG" alt="Автоплощадка" className="img-fluid mb-4" />
                                </div>
                                <div className="col-lg-8 ml-auto order-lg-1">
                                    <p className="mb-4">
                                        «БелАвтоЭксперт» - автомобильная компания, которая занимается продажей и обслуживанием автомобилей.
                                        В нашем автосалоне Вы найдете автомобили известных мировых брендов на любой вкус и бюджет.
                                    </p>
                                    <p className="mb-3">
                                        Отличительные особенности нашей компании - профессионализм сотрудников и индивидуальный подход к каждому клиенту.
                                    </p>
                                    <p className="mb-3">
                                        Мы всегда на связи. С удовольствием готовы помочь c покупкой и продажей автомобиля.
                                    </p>
                                    <p className="mb-3">

                                        У нас Вы можете купить любое транспортное средство на выгодных условиях,
                                        поставить свою машину на продажу по Вашей цене, а также воспользоваться качественными услугами нашего автосервиса.

                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>

)

}

const itemData= [
    {
        img: '/cars.JPG',
        title: 'Автоплощадка',
    },
    {
        img: '/building_main.JPEG',
        title: 'Building',
    }
];