import { createTheme } from '@mui/material/styles';

import {grey, red} from '@mui/material/colors';
import {ruRU} from '@mui/x-data-grid/locales';

export const redMiddle = red[700];

export const theme = createTheme({
    palette: {
        primary: {
            main: '#b71c1c',
        },
        secondary: {
            main: '#c62828',
        },
        white:{
            main: grey[800]
        },
        greyDark: {
            main: grey[50]
        }
    },

},
    ruRU);

