import React from "react";

export default function Footer() {
    return (
        <footer className="site-footer">
            <div className="container">

                <div className="d-flex flex-wrap justify-content-between">
                    <div className="p-2">
                        <h2 className="footer-heading mb-3">ООО "БелАвтоЭксперт"</h2>
                        <p>УНП 691996500</p>
                        <p>220062, г. Минск, <br/>ул. Тимирязева 119, корпус 1</p>
                    </div>
                    <div className="p-2">
                        <h2 className="footer-heading mb-4">Меню сайта</h2>
                        <ul className="site-menu main-menu ml-auto list-unstyled">
                            <li><a href="/" className="nav-link text-uppercase">Главная</a></li>
                            <li><a href="/listing" className="nav-link text-uppercase active">Авто</a></li>
                            <li><a href="/servise" className="nav-link text-uppercase">Услуги</a></li>
                            <li><a href="/questions" className="nav-link text-uppercase">Вопросы</a></li>
                            <li><a href="/blog" className="nav-link text-uppercase">Блог</a></li>
                            <li><a href="/contact" className="nav-link text-uppercase">Контакты</a></li>
                        </ul>
                    </div>
                    <div className="p-2">
                        <h2 className="footer-heading mb-4">Прочие ссылки</h2>
                        <ul className="list-unstyled">
                            <li><a href="/loan">Лизинг/Кредит</a></li>
                        </ul>
                    </div>
                    <div className="p-2">
                        <h2 className="footer-heading mb-4">Контактная информация</h2>
                        <ul className="list-unstyled footer-link">
                            <li className="d-block mb-3">
                                <span className="d-block text-dark">Телефоны:</span>
                                <span className="d-block">
            <a href="tel:+375291888969"><small> +375</small>(29) 188-89-69 <small>A1</small></a>
        </span>
                                <span className="d-block">
            <a href="tel:+375296889033"><small> +375</small>(29) 688-90-33 <small>A1</small></a>
        </span>
                                <span className="d-block">
             <a href="tel:+375336889033"><small> +375</small>(33) 688-90-33 <small>MTC</small></a>
        </span>
                                <span className="d-block">
             <a href="tel:+79993772122"><small> +7</small>(999) 377-21-22 <small>Мегафон</small></a>
        </span>
                            </li>
                            <li className="d-block mb-3">
                                <span className="d-block text-dark">График работы:</span>
                                <span>ежедневно 8:00 - 21:00</span>
                            </li>
                            <li className="d-block mb-3">
                                <span className="d-block text-dark">Мессенджеры:</span>
                                <span className="d-block">
           <a href="tg://resolve?domain=Igorek1978"><span className="icon-telegram"></span> Telegram</a>
        </span>
                                <span className="d-block">
           <a href="viber://chat?number=%2B375291888969"><span className="icon-whatsapp"></span> Viber</a>
        </span>
                                <span className="d-block">
           <a href="https://wa.me/375291888969"><span className="icon-whatsapp"></span> WhatsApp</a>
        </span>
                            </li>
                            <li className="d-block mb-3">
                                <span className="d-block text-dark">Соц. сети:</span>
                                <span className="d-block">
           <a href="https://www.instagram.com/belavtoexpert/"><span className="icon-instagram"></span> Instagram</a>
        </span>
                            </li>
                            <li className="d-block mb-3">
                                <span className="d-block text-dark">Email:</span>
                                <span>
            <a href="mailto:belavtoex@yandex.ru">belavtoex@yandex.ru</a>
        </span>
                            </li>
                            <li className="d-block mb-3">
                                <span className="d-block text-dark">Адрес:</span>
                                <span>ул. Тимирязева 119, корпус 1 </span>

                            </li>
                        </ul>
                    </div>

                </div>

                <div className="row mt-2 text-center">
                    <div className="col-md-12">
                        <div className="border-top pt-5">
                            <p>Все права защищены ©
                                <script>document.write(new Date().getFullYear());</script>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </footer>
    );
}