import React, {useEffect, useState} from "react";
import VehicleListItem from "../vehicle/VehicleListItem";
import Filter from "../Filter";
import {paginationPageItems, vehicleListSectionHeadingMessage} from "../Constants";
import SectionHeading from "../SectionHeading";
import ReactGA from "react-ga4";
import InfiniteScroll from "react-infinite-scroll-component";
import {LinearProgress, ThemeProvider} from "@mui/material";
import {theme} from "../MuiCustomization";
import {useSearchParams} from "react-router-dom";
import ListingActionBlock from "../ListingActionBlock";

export default function VehicleOrderList() {

    const [searchParams, setSearchParams] = useSearchParams();

    const [filterBrands, setFilterBrands] = useState(searchParams.get('brands')
        ? searchParams.get('brands').split(',') :[]);

    const [vehicles, setVehicles] = useState([]);

    /*Pagination index*/
    const [index, setIndex] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const [sortBy, setSortBy] = useState(searchParams.get('sortBy') ? searchParams.get('sortBy') : "INPUT");

    useEffect(() => {

        document.title = 'Авто и электромобили из Китая в автосалоне БелАвтоЭксперт | Каталог и цены авто из Китая';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta = document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Авто из Китая, кредит, выгодный трейдин');

        let keywordsMeta = document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content", 'Авто из Китая, кредит, выгодный трейдин');

        loadMoreArticles();

    }, []);

    const loadMoreArticles = () => {

        getArticleList(filterBrands, index, sortBy)
            .then(articleList => {
                setVehicles((prevVehicles) => [...prevVehicles, ...articleList]);
                setHasMore(articleList.length > 0);
                setIndex(index + 1);
            });
    }

    const resetArticles = (filterBrands, index, sortBy) => {
        setVehicles([]);
        setFilterBrands(filterBrands);
        setSortBy(sortBy);
        getArticleList(filterBrands, index, sortBy)
            .then(articleList => {
                setVehicles(articleList);
                setHasMore(articleList.length > 0);
                setIndex(index + 1);
            });
    }

    function getArticleList(filterBrands, index, sortBy) {

        let brandsParam = (filterBrands && filterBrands.length > 0) ? ("&brands=" + filterBrands.join(",")) : "";

        let url = "/api/cars?index=" + index
            + "&sortBy="+ sortBy
            + "&itemsPerPageCount="+ paginationPageItems
            + (brandsParam ? (brandsParam) : "")
            + "&vehicleCategory=CHN_ORDER";

        return fetch(url)
            .then(response => response.json())
            .then(data => {

                /* Update url */
                let url = new URL(window.location);
                if (sortBy) {
                    url.searchParams.set("sortBy", sortBy);
                }

                if (filterBrands && filterBrands.length > 0) {
                    url.searchParams.set("brands", filterBrands.join(","));
                } else {
                    url.searchParams.delete("brands");
                }

                if (paginationPageItems) {
                    url.searchParams.set("itemsPerPageCont", paginationPageItems.toString());
                }

                url.searchParams.set("vehicleCategory","CHN_ORDER");

                window.history.pushState(null, '', url.toString());

                return data
            });
    }

    function handleFilterBrandsUpdate(filterBrands) {
        resetArticles(filterBrands, 0, sortBy);
    }

    function handleSortByUpdate(sortBy) {
        resetArticles(filterBrands, 0, sortBy);
    }

    return (
        <div className="site-section">
            <div className="container">
                <SectionHeading sectionHeadingTitle={"Авто из Китая в нашем автосалоне"}
                                sectionHeadingMessage={"Автомобили в наличии и под заказ."}/>

                {/*<Filter filterBrands={filterBrands}
                        onUpdateFilterBrands={handleFilterBrandsUpdate}/>*/}

                {(vehicles.length === 0 && hasMore) ?
                    (<ThemeProvider theme={theme}>
                        <LinearProgress color="secondary"/>
                    </ThemeProvider>)
                    :
                    (vehicles.length > 0 ?
                        <>
                            <ListingActionBlock handleSortByUpdate={handleSortByUpdate} sortBy={sortBy}/>
                            <InfiniteScroll
                                dataLength={vehicles.length}
                                next={loadMoreArticles}
                                hasMore={hasMore}
                                loader={
                                    <ThemeProvider theme={theme}>
                                        <LinearProgress color="secondary"/>
                                    </ThemeProvider>}
                            >

                                <div className="row mt-4 w-100">
                                {vehicles.map((vehicle, index) => (
                                    <VehicleListItem vehicleObj={vehicle}/>
                                ))}

                            </div>
                            </InfiniteScroll>
                    </>
                    : <p>Нет автомобилей для просмотра.</p>)
                }

            </div>
        </div>
    );

}