import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DirectionsCarFilledTwoToneIcon from '@mui/icons-material/DirectionsCarFilledTwoTone';
import CarRentalTwoToneIcon from '@mui/icons-material/CarRentalTwoTone';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import CreditScoreTwoToneIcon from '@mui/icons-material/CreditScoreTwoTone';
import CarRepairTwoToneIcon from '@mui/icons-material/CarRepairTwoTone';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import NewspaperTwoToneIcon from '@mui/icons-material/NewspaperTwoTone';
import ViewListIcon from '@mui/icons-material/ViewList';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import PermPhoneMsgTwoToneIcon from '@mui/icons-material/PermPhoneMsgTwoTone';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import EmojiTransportationTwoToneIcon from '@mui/icons-material/EmojiTransportationTwoTone';
import FeedTwoToneIcon from '@mui/icons-material/FeedTwoTone';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import DescriptionIcon from '@mui/icons-material/Description';
import {Collapse, SwipeableDrawer} from "@mui/material";

import {useNavigate} from "react-router-dom"
import {CarCrash, ExpandLess, ExpandMore, TireRepair} from "@mui/icons-material";
import {grey} from "@mui/material/colors";
import {breadcrumbNameMap} from "../Constants";

export default function OffCanvasSidebar() {
    const [sideBarOpen, setSideBarOpen] = React.useState(false);

    const [carSaleItemOpen, setCarSaleItemOpen] = React.useState(false);
    const [vehicleOrderItemOpen, setVehicleOrderItemOpen] = React.useState(false);
    const [serviceItemOpen, setServiceItemOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setSideBarOpen(newOpen);
    };

    const navigate = useNavigate();

    const handleExpandItemClick = (expandName) => {
        if ('carSaleExpandItem' === expandName) {
            setCarSaleItemOpen(!carSaleItemOpen);
        }
        if ('vehicleOrderExpandItem' === expandName) {
            setVehicleOrderItemOpen(!vehicleOrderItemOpen);
        }
        if ('serviceExpandItem' === expandName) {
            setServiceItemOpen(!serviceItemOpen);
        }

    };

    const goPage = (link) => {
        setSideBarOpen(false);
        navigate(link);
    };

    {/* Router functionality */}
    const DrawerList = (
        <Box sx={{width: 250}} role="presentation">
            <List>
                {[{text: 'Главная', icon: <><HomeTwoToneIcon color="primary"/></>, link: "/"},
                    {text: breadcrumbNameMap["/listing"].title, icon: <><DirectionsCarFilledTwoToneIcon color="primary"/></>, link: "/listing"},
                    {text: 'Авто под заказ',
                        icon: <><CarRentalTwoToneIcon color="primary"/></>,
                        expandName: 'vehicleOrderExpandItem',
                        isOpened: vehicleOrderItemOpen,
                        subItems: [
                            {text: 'Информация об услугах', icon: <><LoyaltyIcon color="primary"/></>, link: "/vehicleOrder"},
                            {
                                text: breadcrumbNameMap["/vehicleOrder/catalog"].title,
                                icon: <><DirectionsCarFilledIcon color="primary"/></>,
                                link: "/vehicleOrder/catalog"
                            },
                            {
                                text: 'Авто доступные к заказу',
                                icon: <><ViewListIcon color="primary"/></>,
                                link: "/vehicleOrder/listing"
                            },
                            {
                                text: breadcrumbNameMap["/vehicleOrder/pricing"].title,
                                icon: <><PriceCheckIcon color="primary"/></>,
                                link: "/vehicleOrder/pricing"
                            }]
                        },
                    {text: 'Кредит/лизинг', icon: <><CreditScoreTwoToneIcon color="primary"/></>, link: "/loan"},
                    {text: 'Сервисные услуги',
                        icon: <><CarRepairTwoToneIcon color="primary"/></>,
                        expandName: 'serviceExpandItem',
                        isOpened: serviceItemOpen,
                        subItems: [
                            {text: breadcrumbNameMap["/service"].title,
                                icon: <><DescriptionIcon color="primary"/></>,
                                link: "/service"},
                            {
                                text: breadcrumbNameMap["/service/servicePrice"].title,
                                icon: <><CarCrash color="primary"/></>,
                                link: "/service/servicePrice"
                            },
                            {
                                text: breadcrumbNameMap["/service/tireServicePrice"].title,
                                icon: <><TireRepair color="primary"/></>,
                                link: "/service/tireServicePrice"
                            }]},
                    {text: 'Помощь в продаже авто',
                        icon: <><EmojiTransportationTwoToneIcon color="primary"/></>,
                        expandName: 'carSaleExpandItem',
                        isOpened: carSaleItemOpen,
                        subItems: [
                            {text: 'Информация об услугах', icon: <><FeedTwoToneIcon color="primary"/></>, link: "/commission"},
                            {
                                text: 'Ответы на вопросы',
                                icon: <><QuestionAnswerTwoToneIcon color="primary"/></>,
                                link: "/commissionQuestions"
                            }]
                    },
                    {text: 'Блог', icon: <><NewspaperTwoToneIcon color="primary"/></>, link: "/blog"},
                    {text: 'Контакты', icon: <><PermPhoneMsgTwoToneIcon color="primary"/></>, link: "/contacts"},
                    {text: 'Адрес', icon: <><LocationOnTwoToneIcon color="primary"/></>, link: "/location"}
                ]

                    .map((item, index) => (

                        <>
                            <ListItem key={item.text} disablePadding>

                                <ListItemButton
                                    onClick={()=> {item.link ? goPage(item.link) : handleExpandItemClick(item.expandName)}}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.text}/>
                                    {item.subItems && (item.isOpened ? <ExpandLess/> : <ExpandMore/>)}
                                </ListItemButton>
                            </ListItem>

                            {item.subItems && item.subItems.map((subItem, index) => (
                                <Collapse in={item.isOpened} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton sx={{pl: 4}} onClick={() => goPage(subItem.link)}>
                                            <ListItemIcon>
                                                {subItem.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={subItem.text}/>
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            ))}
                        </>

                    ))}
            </List>
        </Box>
    );

    return (
        <>

            <Button variant="contained" style={{color: "black", backgroundColor: grey[50], border: "solid 0.5px #b71c1c" }} onClick={toggleDrawer(true)} fullWidth
                startIcon={<MenuTwoToneIcon color="secondary" />}>Меню
            </Button>
            <SwipeableDrawer open={sideBarOpen} onClose={toggleDrawer(false)}>
                {DrawerList}
            </SwipeableDrawer>

        </>
    );
}