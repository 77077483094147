import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import {DataGrid, GridToolbarContainer, GridToolbarFilterButton} from '@mui/x-data-grid';
import SectionHeading from "../SectionHeading";
import RelevantLinkBlock from "../fragments/RelevantLinkBlock";

export default function VehicleOrderListing() {

    let relevantUrls = ["/vehicleOrder", "/vehicleOrder/catalog", "/vehicleOrder/pricing",
        "/loan", "/blog/3"];

    useEffect(() => {

        document.title = 'Заказать автомобиль из Китая в БелАвтоЭксперт';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta=document.querySelector("meta[name='description']");
        descMeta.setAttribute("content",
            'Список авто доступных к заказу. ');

        let keywordsMeta=document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content",
            'электро машины из Китая, Byd, Geely, Lixiang, выгодные цены на авто под заказ');

    }, []);

    const columns = [

        { field: 'id', headerName: 'id', filterable: false},
        {
            field: 'name',
            headerName: 'Наименование',
            hideable: false,
            flex: 1
        }
    ];

    const rows = [
        {
            "id": 1,
            "name": "Audi 50 e-tron quattro glory type brocade suit"
        },
        {
            "id": 2,
            "name": "Buick Velite 6 430km Comfort 2024"
        },
        {
            "id": 3,
            "name": "Buick Velite 6 430km Yuexiang 2024"
        },
        {
            "id": 4,
            "name": "BYD Dolphin Honor Edition 302km Vitality 2024"
        },
        {
            "id": 5,
            "name": "BYD Dolphin Honor Edition 401km Knight 2024"
        },
        {
            "id": 6,
            "name": "BYD Dolphin Honor Edition 420km Fashion 2024"
        },
        {
            "id": 7,
            "name": "BYD Dolphin Honor Edition 420km Free 2024"
        },
        {
            "id": 8,
            "name": "BYD Segull Flying Version 2024"
        },
        {
            "id": 9,
            "name": "BYD Segull Free Edition 2024"
        },
        {
            "id": 10,
            "name": "BYD Segull Vitality Edition 2024"
        },
        {
            "id": 11,
            "name": "BYD Song L 550km excellent 2024"
        },
        {
            "id": 12,
            "name": "BYD Song L 550km transcendent 2024"
        },
        {
            "id": 13,
            "name": "BYD Song L 602km AWD excellent 2024"
        },
        {
            "id": 14,
            "name": "BYD Song L 662km excellent 2024"
        },
        {
            "id": 15,
            "name": "BYD Song L 662km transcendent 2024"
        },
        {
            "id": 16,
            "name": "BYD Song Plus EV 520km Flagship 2024"
        },
        {
            "id": 17,
            "name": "BYD Song Plus EV 605km Flagship Plus 2024"
        },
        {
            "id": 18,
            "name": "BYD Song Plus EV 520km Luxury 2024"
        },
        {
            "id": 19,
            "name": "BYD Song Plus EV 520km Premium 2024"
        },
        {
            "id": 20,
            "name": "BYD Tnag EV Champion 635km 4WD Flaghip 2024"
        },
        {
            "id": 21,
            "name": "BYD Yuan Plus 430KM Leading 2024"
        },
        {
            "id": 22,
            "name": "BYD Yuan Plus 430KM Transcendent 2024"
        },
        {
            "id": 23,
            "name": "BYD Yuan Plus 510KM Excellent 2024"
        },
        {
            "id": 24,
            "name": "BYD Yuan Plus 510KM Leading 2024"
        },
        {
            "id": 25,
            "name": "BYD Yuan Plus 510KM Transcendent 2024"
        },
        {
            "id": 26,
            "name": "Changan Qiyuan A07 200 Air 2024"
        },
        {
            "id": 27,
            "name": "Changan Qiyuan A07 200 Max 2024"
        },
        {
            "id": 28,
            "name": "Changan Qiyuan A07 200 Pro 2024"
        },
        {
            "id": 29,
            "name": "Chery Tiggo 9 2.0T AT AWD extreme version with 5 seats"
        },
        {
            "id": 30,
            "name": "Chery Tiggo 9 2.0T AT AWD Ultimate version with 5 seat"
        },
        {
            "id": 31,
            "name": "Deepal S7 121 Max 2024"
        },
        {
            "id": 32,
            "name": "Deepal S7 121 Pro 2024"
        },
        {
            "id": 33,
            "name": "Deepal S7 200 Max 2024"
        },
        {
            "id": 34,
            "name": "Deepal SL03 135 plus 2024"
        },
        {
            "id": 35,
            "name": "Deepal SL03 135 Pro 2024"
        },
        {
            "id": 36,
            "name": "Deepal SL03 610 Max 2024"
        },
        {
            "id": 37,
            "name": "GAC GS3 Shadowspeed 270T Enjoy Edition"
        },
        {
            "id": 38,
            "name": "GAC GS3 Shadowspeed 270T Jingqu Edition"
        },
        {
            "id": 39,
            "name": "GAC GS3 Shadowspeed 270T Jinzhi Edition"
        },
        {
            "id": 40,
            "name": "GAC GS3 Yingsu 270T R-STYLE version"
        },
        {
            "id": 41,
            "name": "GAC GS8 2.0TM Hybrid AWD Premium"
        },
        {
            "id": 42,
            "name": "GAC GS8 2.0TM Hybrid AWD Premium + Comfort Package"
        },
        {
            "id": 43,
            "name": "GAC GS8 Pilot Series 2.0TGDI AWD luxury smart"
        },
        {
            "id": 44,
            "name": "Geely Binyue Cool Champion Edition"
        },
        {
            "id": 45,
            "name": "Geely Boyue Cool Friends Edition"
        },
        {
            "id": 46,
            "name": "Geely Boyue Cool Smart"
        },
        {
            "id": 47,
            "name": "Geely Boyue Cool Smart Connected"
        },
        {
            "id": 48,
            "name": "Geely Boyue Cool Zhilian+"
        },
        {
            "id": 49,
            "name": "Geely Boyue Cool Zhuizun"
        },
        {
            "id": 50,
            "name": "Geely Monjaro 1.5T Thor Hi·F Super Rui"
        },
        {
            "id": 51,
            "name": "Geely Monjaro 1.5T Thor Hi·F Super Speed"
        },
        {
            "id": 52,
            "name": "Geely Monjaro 2.0TD DCT flagship model"
        },
        {
            "id": 53,
            "name": "Geely Monjaro 2.0TD DCT premium model"
        },
        {
            "id": 54,
            "name": "Honda ENS1 eChi"
        },
        {
            "id": 55,
            "name": "Honda ENS1 e-dong"
        },
        {
            "id": 56,
            "name": "Honda ENS1 e-jing"
        },
        {
            "id": 57,
            "name": "Honda ENS1 e-xing"
        },
        {
            "id": 58,
            "name": "iCar 03 AWD long range advanced version 2024"
        },
        {
            "id": 59,
            "name": "iCar 03 AWD long range version 2024"
        },
        {
            "id": 60,
            "name": "iCar 03 AWD long-range smart driving version 2024"
        },
        {
            "id": 61,
            "name": "Leapmotor C10 210 Comfort 2024"
        },
        {
            "id": 62,
            "name": "Leapmotor C10 210 Smart Drive 2024"
        },
        {
            "id": 63,
            "name": "Leapmotor C10 210 Smart Enjoy 2024"
        },
        {
            "id": 64,
            "name": "Leapmotor C10 410 Comfort 2024"
        },
        {
            "id": 65,
            "name": "Leapmotor C10 530 Comfort 2024"
        },
        {
            "id": 66,
            "name": "Leapmotor C10 530 Smart Drive 2024"
        },
        {
            "id": 67,
            "name": "Leapmotor C10 530 Smart Enjoy 2024"
        },
        {
            "id": 68,
            "name": "Link&Co 06 EM-P 126 Halo 2024"
        },
        {
            "id": 69,
            "name": "Link&Co 06 EM-P 126 Pro 2024"
        },
        {
            "id": 70,
            "name": "Link&Co 06 EM-P 56 Pro 2024"
        },
        {
            "id": 71,
            "name": "Lixiang L7 AIR 2024"
        },
        {
            "id": 72,
            "name": "Lixiang L7 MAX 2024"
        },
        {
            "id": 73,
            "name": "Lixiang L7 PRO 2024"
        },
        {
            "id": 74,
            "name": "Lixiang L7 ULTRA 2024"
        },
        {
            "id": 75,
            "name": "Lixiang L9 PRO 2024"
        },
        {
            "id": 76,
            "name": "Lixiang L9 ULTRA 2024"
        },
        {
            "id": 77,
            "name": "Lixiang Mega"
        },
        {
            "id": 78,
            "name": "Lynk&Co 08 220 Performance Halo 2024"
        },
        {
            "id": 79,
            "name": "Lynk&Co 08 Time Limited edition 2024"
        },
        {
            "id": 80,
            "name": "MB 260 Facelift 2024"
        },
        {
            "id": 81,
            "name": "MB EQB Facelift 260 2024"
        },
        {
            "id": 82,
            "name": "Nissan Ariya 500 2024"
        },
        {
            "id": 83,
            "name": "Nissan Ariya 600 2024"
        },
        {
            "id": 84,
            "name": "Nissan Ariya 600 Plus 2024"
        },
        {
            "id": 85,
            "name": "Nissan Ariya Performance 2024"
        },
        {
            "id": 86,
            "name": "Nissan Ariya Performance Plus 2024"
        },
        {
            "id": 87,
            "name": "Nissan X-Trail e-Power extreme version 2024"
        },
        {
            "id": 88,
            "name": "Tesla 3+ Long Range 2024"
        },
        {
            "id": 89,
            "name": "Tesla Y Long Range 2024"
        },
        {
            "id": 90,
            "name": "Toyota Crown Kluger Hybrid 2.5L E-CVT AWD Flagship"
        },
        {
            "id": 91,
            "name": "Voyah Free 2024"
        },
        {
            "id": 92,
            "name": "VW id4 CROZZ PRIME 2024"
        },
        {
            "id": 93,
            "name": "VW id4 CROZZ PRO 2024"
        },
        {
            "id": 94,
            "name": "VW id4 CROZZ 纯享限量版 2024"
        },
        {
            "id": 95,
            "name": "VW id4 CROZZ PURE+ 2024"
        },
        {
            "id": 96,
            "name": "VW id6 CROZZ PRIME 2024"
        },
        {
            "id": 97,
            "name": "VW id6 CROZZ PRO 2024"
        },
        {
            "id": 98,
            "name": "VW id6 CROZZ PURE 2024"
        },
        {
            "id": 99,
            "name": "VW id6 CROZZ PURE+ 2024"
        },
        {
            "id": 100,
            "name": "Zeekr 001 ME 2024"
        },
        {
            "id": 101,
            "name": "Zeekr 001 WE AWD 2024"
        },
        {
            "id": 102,
            "name": "Zeekr 001 YOU 2024"
        }
    ];

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    return (
        <div className="site-section bg-light">
            <div className="container">
                <SectionHeading sectionHeadingTitle={"Авто из Китая доступные к заказу"}/>
                <Grid container spacing={0} sx={{mb: 4}}>
                    <Grid xs={12} md={8} lg={9}>
                        <p>Салон БелАвтоЭксперт предлагает большой выбор моделей и комплектаций,
                            конкурентоспособные цены, помощь в оформлении <a href="/loan"> лизинга/кредита</a>,
                            <a href="/vehicleOrder"> сопровождение на всех этапах оформления сделок</a>.
                        </p>
                    </Grid>
                    <Grid xs={12} md={4} lg={3} >
                    </Grid>
                </Grid>

                <Grid container spacing={0}>
                    <Grid xs={12} md={8} lg={9} sx={{mb: 4}} >

                        <div className="row">
                            <div className="col">
                                <Box>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        slots={{
                                            toolbar: CustomToolbar,
                                        }}
                                        slotProps={{
                                            toolbar: { counter: rows.length },
                                        }}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 100,
                                                },
                                            },
                                            columns: {
                                                columnVisibilityModel: {
                                                    id: false
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[25, 50, 100]}
                                        disableRowSelectionOnClick={true}
                                    />
                                </Box>
                            </div>

                        </div>
                    </Grid>
                    <Grid xs={12} md={4} lg={3} sx={{mb: 4}}>

                        <RelevantLinkBlock relevantUrls={relevantUrls}/>

                    </Grid>
                </Grid>

            </div>
        </div>

    );

}