import React from "react";

export default function ListingActionBlock({handleSortByUpdate, sortBy}) {
    return (
        <div className="row mb-5">
            <div className="col-sm-auto col-md-6">
                <div className="actions">
                    <select className="form-select" value={sortBy}
                            onChange={(e) => handleSortByUpdate(e.target.value)}>
                        <option value="INPUT">Упорядочить по умолчанию</option>
                        <option value="LOW_PRICE_FIRST">Цена по возрастанию</option>
                        <option value="HIGH_PRICE_FIRST">Цена по убыванию</option>
                    </select>
                </div>
            </div>
        </div>
    );
}