import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import AddressFragment from "./AddressFragment";

export default function Address() {

    useEffect(() => {

        document.title = 'Адрес';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta=document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Адрес');

        let keywordsMeta=document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content", 'Адрес БелАвтоЭксперт');

    }, []);

    return (
        <div className="site-section">
            <div className="container">
                <div className="row">
                    <div><h2 className="section-heading"><strong>Адрес</strong></h2><p
                        className="mb-5">Автоплощадка на 180 машиномест, напротив МРЭО ГАИ, на месте бывшего авторынка Ждановичи.</p>
                    </div>
                </div>
                <AddressFragment/>
            </div>
        </div>

    );

}