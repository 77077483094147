export const exchangeRate = 3.2;

/*How many items is shown per page*/
export const paginationPageItems = 20;

/* Commission cost*/
export const commissionCost = 650;

/* local storage variable names */
export const servicePriceAC = "От 30.00 рублей";
export const servicePriceOil = "От 40.00 рублей";
export const servicePriceDisk = "От 50.00 рублей";
export const servicePriceBodyProtect = "650.00 рублей";

/* Router functionality */
export const breadcrumbNameMap = {
    '/': {title: 'Главная', description: ''},

    '/listing': {title: 'Каталог авто'},
    '/listing/product': {title: 'Просмотр'},

    '/vehicleOrder': {title: 'Авто под заказ', description: 'Общая информация, условия, бонусы от компании'},
    '/vehicleOrder/catalog': {
        title: 'Каталог авто',
        description: 'Список автомобилей из Китая, которые Вы можете заказать у нас.'
    },
    '/vehicleOrder/listing': {title: 'Авто доступные к заказу', description: 'Список авто доступных к заказу'},
    '/vehicleOrder/pricing': {
        title: 'Стоимость авто',
        description: 'Детальный разбор всех компонентов итоговой цены на авто из Китая'
    },

    '/loan': {
        title: 'Кредит и лизинг',
        description: "Наши компании-партнёры могут подобрать для Вас наиболее выгодные условия кредита или лизинга для покупки электромобиля."
    },

    '/service': {title: 'Услуги', description: "Информация о предоставляемых услугах"},
    '/service/servicePrice': {title: 'Прайс СТО', description: "Актуальные цены на техническое обслуживание"},
    '/service/tireServicePrice': {title: 'Прайс монтажа и хранения шин', description: "Актуальные цены на шиномонтаж и хранение шин"},

    '/commission': {title: 'Помощь в продаже авто'},
    '/commissionQuestions': {title: 'Ответы на вопросы'},
    '/contacts': {title: 'Контакты'},
    '/about': {title: 'О нас'},
    '/location': {title: 'Адрес'},
    '/blog': {title: 'Блог'},
    '/blog/1': {
        title: 'Покупка авто в лизинг',
        description: "Что такое лизинг, возвратный лизинг. Оценка платежеспособности. Условия для физ. и юр. лиц."
    },
    '/blog/2': {title: 'Нулевая растаможка для электрокаров'},
    '/blog/3': {title: 'BYD на рынке электрокаров', description: "О компании BYD."}
};