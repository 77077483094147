import React from "react";
import AddressFragment from "./contact/AddressFragment";

export default function ContactsFragment() {
    return (
        <div className="bg-white p-3 p-md-5">
            <div className="text-dark mb-3 fs-4">Контактная информация</div>
            <ul className="list-unstyled footer-link">
                <li className="d-block mb-3">
                    <span className="d-block text-dark fw-bold">Телефоны:</span>
                    <span className="d-block">
                            <a href="tel:+375291888969"><small> +375</small>(29) 188-89-69 <small>A1</small></a>
                        </span>
                    <span className="d-block">
                            <a href="tel:+375296889033"><small> +375</small>(29) 688-90-33 <small>A1</small></a>
                        </span>
                    <span className="d-block">
                             <a href="tel:+375336889033"><small> +375</small>(33) 688-90-33 <small>MTC</small></a>
                        </span>
                    <span className="d-block">
                             <a href="tel:+79993772122"><small> +7</small>(999) 377-21-22 <small>Мегафон</small></a>
                        </span>
                </li>
                <li className="d-block mb-3">
                    <span className="d-block text-dark  fw-bold">График работы:</span>
                    <span>ежедневно 8:00 - 21:00</span>
                </li>
                <li className="d-block mb-3">
                    <span className="d-block text-dark  fw-bold">Мессенджеры:</span>
                    <span className="d-block">
                           <a href="tg://resolve?domain=Igorek1978"><span className="icon-telegram"></span> Telegram</a>
                        </span>
                    <span className="d-block">
                           <a href="viber://chat?number=%2B375291888969"><span className="icon-whatsapp"></span> Viber</a>
                        </span>
                    <span className="d-block">
                           <a href="https://wa.me/375291888969"><span className="icon-whatsapp"></span> WhatsApp</a>
                        </span>
                </li>
                <li className="d-block mb-3">
                    <span className="d-block text-dark fw-bold">Соц. сети:</span>
                    <span className="d-block">
                       <a href="https://www.instagram.com/belavtoexpert/"><span className="icon-instagram"></span> Instagram</a>
                    </span>
                </li>
                <li className="d-block mb-3">
                    <span className="d-block text-dark fw-bold">Email:</span>
                    <span>
                            <a href="mailto:belavtoex@yandex.ru">belavtoex@yandex.ru</a>
                        </span>
                </li>
                <li className="d-block mb-3">
                    <span className="d-block text-dark fw-bold">Адрес:</span>
                    <AddressFragment/>
                </li>
            </ul>
        </div>
    );
}