import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import {Grid, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import {theme} from "../MuiCustomization";
import RelevantLinkBlock from "../fragments/RelevantLinkBlock";
import {servicePriceAC, servicePriceBodyProtect, servicePriceDisk, servicePriceOil} from "../Constants";
import {Link} from "react-router-dom";

export default function Service() {

    let relevantUrls = ["/service/servicePrice", "/service/tireServicePrice"];

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
        defaultMatches: true
    });

    useEffect(() => {

        document.title = 'Авто под заказ';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta=document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Автомобили из китая под заказ');

        let keywordsMeta=document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content", 'Авто из Китая под заказ');

    }, []);

    return (
        <div className="site-section">
            <div className="container">

                <Grid container spacing={0}>
                    <Grid xs={12} md={8} lg={9}>
                        <h2 className="section-heading"><strong>Сервисные услуги</strong></h2>
                        <p className="me-2">
                            Если вы ищите надежный автосервис с минимальными затратами времени и средств с Вашей стороны,
                            воспользуйтесь услугами СТО в Минске — БелАвтоЭксперт и получите качественное обслуживание Вашего автомобиля.<br/>
                            Мы используем только лучшие и проверенные материалы и комплектующие и даём гарантию на все виды работ и запчасти.
                        </p>

                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                {/*Шиномонтаж*/}
                                <Grid item xs={12} md={8} mt={3}>
                                    <ServiceItemTitle>Шиномонтаж</ServiceItemTitle>
                                    <p >Безопасность на дороге начинается с посадки шин. Наши профессионалы -
                                        настоящие мастера в этом деле.
                                        Доверьте свой автомобиль нашей команде и мы гарантируем, что каждое колесо будет установлено
                                        с максимальной точностью.
                                        Мы заботимся о деталях, будь то монтаж, балансировка или правильная затяжка болтов, потому
                                        что качество - наш приоритет.</p>
                                    <p><Link to="/service/tireServicePrice">Цены на шиномонтаж</Link>.</p>
                                </Grid>
                                <Grid item xs={12} md={4} mt={isMobile ? 0 : 3}>
                                    <img src="images/tire-repair.jpeg" mt={isMobile ? 0 : 3} className="img-fluid rounded"/>
                                </Grid>
                                {/*Сезонное хранение шин*/}
                                <Grid item xs={12} md={8} mt={3}>
                                    <ServiceItemTitle>Сезонное хранение шин</ServiceItemTitle>
                                    <p>
                                        Экономьте время и средства с нашим сезонным хранением шин, включая профессиональный
                                        шиномонтаж!
                                        Надежное хранение и полный сервис.
                                    </p>
                                    Наши преимущества:
                                    <ul>
                                        <li>Надежное Хранение: Ваши шины будут находиться под надежным присмотром, гарантируя
                                            сохранность их качества.
                                        </li>
                                        <li>Профессиональный Шиномонтаж: Наш опытный персонал обеспечит правильную установку ваших
                                            шин, гарантируя ваш комфорт и безопасность на дороге.
                                        </li>
                                        <li>Экономия Времени и Средств: Забудьте о заботах по хранению шин — мы берем на себя всю
                                            работу. Экономьте время и деньги с нашим полным сервисом.
                                        </li>
                                    </ul>
                                    Доверьте нам заботу о ваших шинах, и наслаждайтесь беззаботным вождением в любое время года!
                                    <p><Link to="/service/tireServicePrice">Прайс</Link>.</p>
                                </Grid>
                                <Grid item xs={12} md={4} mt={isMobile ? 0 : 3}>
                                    <img src="images/tire-storing.jpg" mt={isMobile ? 0 : 3} className="img-fluid rounded mb-3"/>
                                    <img src="/pictures/autoservice/tire/IMG_6025.JPG" mt={isMobile ? 0 : 3} className="img-fluid rounded"/>
                                </Grid>
                                {/*Антикоррозийная обработка кузова*/}
                                <Grid item xs={12} md={8} mt={3}>
                                    <ServiceItemTitle>Антикоррозийная обработка кузова</ServiceItemTitle>
                                    <p>
                                        Антикоррозийная обработка кузова является одним из лучших способов замедлить процесс износа
                                        кузова, днища и других деталей автомобиля. Обработка гарантирует, что на автомобиле не
                                        появятся очаги коррозии даже после долгих лет эксплуатации. <br/>
                                        Чтобы избежать некачественных материалов или нарушения заводских стандартов, рекомендуем
                                        доверить антикоррозийную обработку профессионалам нашего сервисного центра БелАвтоЭксперт,
                                        расположенного по адресу ул. Тимирязева 119, корпус 1, г. Минск. <br/>
                                        Мы специализируемся на обработке всех моделей и марок автомобилей, следуя строгим
                                        инструкциям производителей, используя только современные высококачественные материалы,
                                        которые обеспечат защиту вашего автомобиля на длительное время. <br/>
                                        Процесс обработки проводится поэтапно, включая демонтаж колес и других защитных элементов,
                                        глубокую мойку, сушку, нанесение защитного материала. Затем собираем всё обратно. Время
                                        выполнения работы зависит от конкретной модели автомобиля.
                                    </p>
                                    <p>Стоимость - <Link to="/service/servicePrice">{servicePriceBodyProtect}</Link>.</p>
                                </Grid>
                                <Grid item xs={12} md={4} mt={isMobile ? 0 : 3}>
                                    <img src="images/body-treatment.jpg" mt={isMobile ? 0 : 3} className="img-fluid rounded"/>
                                </Grid>
                                {/*Проточка тормозных дисков*/}
                                <Grid item xs={12} md={8} mt={3}>
                                    <ServiceItemTitle>Проточка тормозных дисков</ServiceItemTitle>
                                    <p>
                                        Тормозные диски в автомобиле постоянно подвергаются повышенным нагрузкам и достаточно быстро изнашиваются.
                                        В нашем автоцентре Вы всегда можете пройти качественную диагностику состояния тормозной системы.
                                    </p>
                                    <p>
                                        Мы работаем на современном и высокотехнологичном оборудовании, и это позволяет
                                        нашим опытным специалистам при необходимости не только заменить тормозные диски на новые,
                                        но и восстановить рабочую поверхность существующих дисков и сделать их идеально ровными.
                                    </p>
                                    <p>
                                        Проточку тормозных дисков рекомендуем при замене тормозных колодок, а также в
                                        случаях биения, дисбаланса, неравномерной выработке, посторонних звуках и вибрации при торможении.
                                    </p>
                                    <p>
                                        Воспользуйтесь квалифицированными услугами нашего автосервиса, и тормозная система Вашего автомобиля
                                        будет в отличном состоянии, что обеспечит комфортное и безопасное вождение.
                                    </p>
                                    <p><Link to="/service/servicePrice">{servicePriceDisk}</Link>.</p>
                                </Grid>
                                <Grid item xs={12} md={4} mt={isMobile ? 0 : 3}>
                                    <img src="/pictures/autoservice/disks/IMG_6024.JPG" mt={isMobile ? 0 : 3} className="img-fluid rounded mb-3"/>
                                    <img src="/pictures/autoservice/disks/IMG_6026.JPG" mt={isMobile ? 0 : 3} className="img-fluid rounded"/>

                                </Grid>
                                {/*Экспресс-замена масла */}
                                <Grid item xs={12} md={8} mt={3}>
                                    <ServiceItemTitle>Экспресс-замена масла</ServiceItemTitle>
                                    <p>Мы предлагаем несравненное качество услуг по замене масла и фильтров для
                                        автомобилей всех марок.
                                        Профессионально и оперативно, мы заменим моторное масло в вашем двигателе всего за 20 минут.
                                        Наша экспресс-замена масла обеспечивается высококвалифицированными специалистами и позволит
                                        вам сэкономить время.
                                        Предварительная запись на обслуживание гарантирует, что вы не потеряете ни минуты.
                                        У нас всегда в наличии моторные масла высочайшего качества, чтобы ваш автомобиль работал на
                                        пике производительности.</p>
                                    <p><Link to="/service/servicePrice">{servicePriceOil}</Link>.</p>
                                </Grid>
                                <Grid item xs={12} md={4} mt={isMobile ? 0 : 3}>
                                    <img src="/pictures/autoservice/oil/IMG_6027.JPG" alt="Image"
                                         className="img-fluid rounded"/>
                                </Grid>
                                {/*Детейлинг*/}
                                <Grid item xs={12} md={8} mt={3}>
                                    <ServiceItemTitle>Детейлинг</ServiceItemTitle>
                                    <p>
                                        Наши детейлинг-услуги в автоцентре - это безупречное искусство заботы о вашем автомобиле в
                                        каждой детали.<br/>
                                        Мы стараемся предоставить вам наиболее полный набор процедур, чтобы ваш автомобиль всегда
                                        выглядел потрясающе. Наша команда профессионалов занимается всеми аспектами ухода за
                                        автомобилем:
                                    </p>
                                    <ul>
                                        <li>Мойка</li>
                                        <li>Полировка</li>
                                        <li>Нанесение защитных покрытий</li>
                                        <li>Химчистка салона</li>
                                        <li>Восстановление обивки</li>
                                        <li>Полировка панелей и фар</li>
                                        <li>Устранение мелких дефектов кузова</li>
                                        <li>Предпродажная подготовка</li>
                                    </ul>
                                    <p>
                                        Безупречное состояние вашего автомобиля является нашим главным приоритетом, и мы стремимся
                                        превзойти ваши ожидания.
                                        Позвольте нам позаботиться о вашем автомобиле, чтобы он всегда привлекал восхищенные взгляды
                                        и радовал вас каждый день.
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={4} mt={isMobile ? 0 : 3}>
                                    <img src="/pictures/autoservice/detailing/IMG_6028.JPG" mt={isMobile ? 0 : 3} className="img-fluid rounded mb-3"/>
                                    <img src="/pictures/autoservice/detailing/IMG_5660.JPG" mt={isMobile ? 0 : 3} className="img-fluid rounded mb-3"/>
                                    <img src="/pictures/autoservice/detailing/IMG_5659.JPG" mt={isMobile ? 0 : 3} className="img-fluid rounded"/>
                                </Grid>
                                {/*Заправка кондиционеров*/}
                                <Grid item xs={12} md={8} mt={3}>
                                    <ServiceItemTitle>Заправка кондиционеров. {servicePriceAC}</ServiceItemTitle>
                                    <p>
                                        Заправка систем кондиционирования в автомобиле — задача, требующая профессионального подхода и знаний.
                                        Доверьтесь опытным специалистам автосервиса БелАвтоЭксперт, которые отлично разбираются в особенностях климатических
                                        установок. </p>
                                    <p>Мы гарантируем быстрые и качественные сервисные работы по доступной цене.
                                        В нашем автосервисе Вы сможете выполнить заправку и диагностику автокондиционера по выгодным тарифам в Минске.
                                        Обеспечим надежность и комфорт в Вашем автомобиле, сделав каждую поездку приятной и свежей. Не пренебрегайте комфортом — выбирайте профессионалов!
                                    </p>
                                    <p><Link to="/service/servicePrice">{servicePriceAC}</Link>.</p>
                                </Grid>
                                <Grid item xs={12} md={4} mt={isMobile ? 0 : 3}>
                                    <img src="/pictures/autoservice/ac/IMG_6052.JPG" mt={isMobile ? 0 : 3} className="img-fluid rounded mb-3"/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={4} lg={3} >
                        <RelevantLinkBlock relevantUrls={relevantUrls}/>
                    </Grid>
                </Grid>

            </div>
        </div>

    )

    function ServiceItemTitle({children}) {
        return <h3 className="mb-4 section-heading"><strong>{children}</strong></h3>
    }

}