import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import RelevantLinkBlock from "./fragments/RelevantLinkBlock";
import SectionHeading from "./SectionHeading";

export default function Loan() {

    let relevantUrls = ["/blog/1"];

    useEffect(() => {

        document.title = 'Авто в кредит в Минске | Купить новый или авто с пробегом в кредит в Беларуси';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta=document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'БелАвтоЭксперт предлагает купить автомобиль в кредит или лизинг. '
            + 'Большой выбор программ кредитования. Ставка от 4,5%. Срок погашения до 10 лет. Возможность оформления без первоначального взноса.');

        let keywordsMeta=document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content", 'купить авто в кредит, автомобиль в лизинг, выгодный кредит на машину, автокредитование, продажа автомобилей, условия кредитования.');

    }, []);

    return (
        <div className="site-section">
            <div className="container">
                <SectionHeading sectionHeadingTitle={"Сотрудничество по кредитам и лизингам"}
                                sectionHeadingMessage={<>
                                    <p>В автосалоне БелАвтоЭксперт любой автомобиль в наличии и под заказ можно приобрести, оформив
                                        лизинг или кредит.</p>
                                    <p>Мы придаем особое значение выбору наших партнеров, включая лизинговые компании, поэтому мы можем
                                        с уверенностью заявить, что каждому клиенту поможем подобрать оптимальный и удобный способ
                                        приобретения желаемого автомобиля.<br/>
                                        Есть возможность получения лизинга до 10 лет в белорусских рублях по ставке рефинансирования
                                        НБ РБ, плюс 2.5 п.п. с первоначальным взносом всего 10 процентов.</p>
                                    <Stack direction="row" spacing={2}>
                                        <Box>
                                            <a href="tel:+375291888969" className="btn btn-red">
                                                <span className="icon-phone"></span> Получить консультацию
                                            </a>
                                        </Box>
                                        <Box>
                                            <a href="/listing" className="btn btn-white">
                                                <span className="icon-car"></span> Каталог авто
                                            </a>
                                        </Box>
                                    </Stack>
                                </>}/>
                <div className="row">
                    <div className="col-lg-7 ml-auto order-lg-1">
                        <h4 className="my-4">Наши партнеры</h4>
                        <stack gap="{3}">
                            {/*Belarusbank*/}
                            <div className="my-5">
                                <div className="mb-2">
                                    <img src="images/bank/belarusbank.jpg" alt="Беларусбанк>" style={{height: "25px"}}/>
                                </div>
                                <stack gap="{3}">
                                    <div className="mt-3 ms-4">
                                        <div><h5>Кредит «Лёгка ехаць» на приобретение автомобилей, электромобилей в
                                            БелАвтоЭксперт</h5></div>
                                        <div>
                                            <ul>
                                                <li>Без первоначального взноса</li>
                                                <li>Сниженная процентная ставка в 6/12 месяцев 4,5%-9,25%</li>
                                                <li>Срок кредита до 10 лет</li>
                                                <li>Без КАСКО при поручительстве физических лиц</li>
                                                <li>Скидка 50% на заключение договора о залоге при оформлении КАСКО В
                                                    Беларусбанке
                                                </li>
                                                <li>Возможность оформить кредит и заключить кредитный договор онлайн</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://belarusbank.by/ru/fizicheskim_licam/kredit/consumer/40946">подробнее
                                                о кредите «Лёгка ехаць» на официальном сайте Беларусбанка</a>
                                        </div>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <div><h5>Кредит на приобретение автомобиля или электромобиля нового или с
                                            пробегом</h5></div>
                                        <div>
                                            <ul>
                                                <li>Без первоначального взноса</li>
                                                <li>Ставка: 11,5%-14,4%</li>
                                                <li>Срок кредита: до 7-10 лет</li>
                                                <li>Отсрочка по уплате основного долга в течение 6 месяцев</li>
                                                <li>Возможность самостоятельно заполнить онлайн-заявку на кредит</li>
                                                <li>Возможность оформления кредита без предоставления справки о доходах</li>
                                                <li>Возможность предварительного рассмотрения вопроса о выдаче кредита без
                                                    предоставления заявителем договора купли-продажи автомобиля,
                                                    электромобиля
                                                </li>
                                                <li>Удобные способы погашения кредита (в инфокиосках банка, через системы
                                                    Интернет-банкинг, М-банкинг, Автооплата)
                                                </li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://belarusbank.by/ru/fizicheskim_licam/kredit/consumer/avtokredit">подробнее
                                                об автокредите на официальном сайте Беларусбанка</a>
                                        </div>
                                    </div>
                                </stack>
                            </div>

                            {/*Agroleasing*/}
                            <div className="my-5">
                                <div className="mb-2">
                                    <img src="images/bank/agroleasing.jpg" alt="Агролизинг" style={{height: "30px"}}/>
                                </div>
                                <stack gap="{3}">
                                    <div className="mt-3 ms-4">
                                        <div><h5>Новые автомобили Geely реализуемые в рамках Программы</h5></div>
                                        <div>
                                            <ul>
                                                <li>Ставка по лизингу: первые 3 года - (1/2 СР НБРБ (компенсируется в рамках Программы) + 7 п.п. (фиксированная), далее - СР НБРБ + 3 п.п.
                                                </li>
                                                <li>Срок лизинга до 10 лет</li>
                                                <li>Размер задатка по договору лизинга: - от 10% для модели Emgrand</li>
                                                <li>от 40% для моделей Coolray, BelGee X50, - от 25% для моделей Atlas PRO, BelGee X70</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://agroleasing.by/cars_cat/geely/">подробнее
                                                о лизинге на новые автомобили Geely на официальном сайте лизинговой компании ОАО "Агролизинг"</a>
                                        </div>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <div><h5>Новые автомобили Geely вне Программ (все модели), приобретаемые у официальных дилеров</h5></div>
                                        <div>
                                            <ul>
                                                <li>Ставка по лизингу: 12,5 % (СР НБРБ + 3 п.п.)</li>
                                                <li>Срок лизинга до 10 лет</li>
                                                <li>Размер задатка по договору лизинга: - от 20%</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://agroleasing.by/cars_cat/geely/">подробнее
                                                о лизинге на все автомобили Geely на официальном сайте лизинговой компании ОАО "Агролизинг"</a>
                                        </div>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <div><h5>Лизинг автомобилей любых марок и моделей, новых и с пробегом</h5></div>
                                        <div>
                                            <ul>
                                                <li>Ставка по лизингу: 14 % (СР НБРБ + 4,5 п.п.)</li>
                                                <li>Срок лизинга: - до 10 лет (на новые авто), -  до 7 лет (для авто не старше 5 лет), - до 5 лет (для авто не старше 10 лет)</li>
                                                <li>Размер задатка по договору лизинга: - от 30%</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://agroleasing.by/fizicheskim-licam/lizing-transportnyh-sredstv/">подробнее
                                                о лизинге на все автомобили любых марок и моделей, новых и с пробегом на официальном сайте лизинговой компании ОАО "Агролизинг"</a>
                                        </div>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <div><h5>Лизинг электромобилей новых и бывших в эксплуатации не старше 10 лет</h5></div>
                                        <div>
                                            <ul>
                                                <li>Ставка по лизингу: 12,5 % (СР НБРБ + 3 п.п.)</li>
                                                <li>Срок лизинга: - до 10 лет (новые электромобили), -  до 7 лет (для электромобилей не старше 10 лет)</li>
                                                <li>Размер задатка по договору лизинга: - от 30%</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://agroleasing.by/fizicheskim-licam/lizing-elektromobilej/">
                                                подробнее о лизинге  на электромобили на официальном сайте лизинговой компании ОАО "Агролизинг"</a>
                                        </div>
                                    </div>

                                </stack>
                            </div>

                            {/*Proleasing*/}
                            <div className="my-5">
                                <div className="mb-2">
                                    <img src="images/bank/proleasing.jpg" alt="Пролизинг" style={{height: "35px"}}/>
                                </div>
                                <stack gap="{3}">
                                    <div className="mt-3 ms-4">
                                        <div><h5>Лизинг для физических лиц на новые автомобили, автомобили с пробегом и электромобили </h5></div>
                                        <div>
                                            <ul>
                                                <li>Валюта на выбор, гибкий график платежей, сроком до 8 лет.</li>
                                                <li>Пакеты услуг:</li>
                                                    <ul>
                                                        <li>Экспресс: любой автомобиль, без справок, быстрое решение. Аванс от 25%.
                                                            Стоимость до 70 000 USD. Срок лизинга до 96 месяцев. Одобрение сделки до 2 часов.</li>
                                                        <li>Индивидуальный: специальные условия, персональные опции, индивидуальный график платежей.
                                                            Аванс от 10%. Стоимость без ограничений. Срок лизинга до 96 месяцев. Одобрение сделки до 1 дня.</li>
                                                        <li>Моментальный: автомобили до 10 лет, без справок, мгновенное решение.
                                                            Аванс от 35%. Стоимость до 40 000 USD. Срок лизинга до 60 месяцев. Одобрение сделки: уже одобрено.</li>
                                                        <li>Стандарт: любой автомобиль, минимальный пакет документов, быстрое решение.
                                                            Аванс от 10%. Стоимость без ограничений. Срок лизинга до 96 месяцев. Одобрение сделки до 8 часов.</li>
                                                        <li>Возвратный: удобное привлечение средств, честная оценка вашего имущества, быстрое решение.
                                                            Аванс от 25%. Стоимость без ограничений. Срок лизинга до 96 месяцев. Одобрение сделки до 1 дня.</li>
                                                    </ul>
                                            </ul>
                                            <span style={{fontSize: "0.8em"}}>
                                            подробнее о лизинге <a href="https://pro.by/fizicheskie-lica/lizing-novyh-avtomobilej">на новые автомобили </a>,
                                             <a href="https://pro.by/fizicheskie-lica/lizing-avtomobilej-s-probegom"> на автомобили с пробегом</a>,
                                             <a href="https://pro.by/fizicheskie-lica/lizing-elektromobilej"> на электромобили</a> на официальном сайте лизинговой компании <a href="https://pro.by">Proleasing</a>.
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <div><h5>Лизинг для юридических лиц на новые автомобили, автомобили с пробегом и электромобили </h5></div>
                                        <div>
                                            <ul>
                                                <li>Валюта на выбор, гибкий график платежей, сроком до 8 лет.</li>
                                                <li>Пакеты услуг:</li>
                                                <ul>
                                                    <li>Стартап: минимальный пакет документов, быстрое решение для нового бизнеса, возможно без поручительства.
                                                        Аванс от 25%. Стоимость без ограничений. Срок лизинга до 60 месяцев. Одобрение сделки от 1 дня.
                                                    </li>
                                                    <li>Моментальный Pro: быстрое решение для нового бизнеса, минимальный пакет документов, мгновенное решение.
                                                        Аванс от 35%. Стоимость до 40 000 USD. Срок лизинга до 60 месяцев. Одобрение сделки: уже одобрено.
                                                    </li>
                                                    <li>Экспресс Pro: любой автомобиль, без финансовых документов, быстрое решение.
                                                        Аванс от 25%. Стоимость до 69 000 USD. Срок лизинга до 96 месяцев. Одобрение сделки до 2 часов.
                                                    </li>
                                                    <li>Стандарт Pro: любой автомобиль, быстрое решение, больше аванс – меньше документов.
                                                        Аванс от 10%. Стоимость без ограничений. Срок лизинга до 96 месяцев. Одобрение сделки от 1 дня.
                                                    </li>
                                                    <li>Индивидуальный Pro: персональный (сезонный) график платежей, специальные условия, эксклюзивные опции.
                                                        Аванс от 10%. Стоимость без ограничений. Срок лизинга до 96 месяцев. Одобрение сделки от 1 дня.
                                                    </li>
                                                </ul>
                                            </ul>
                                            <span style={{fontSize: "0.8em"}}>
                                                подробнее о лизинге <a href="https://pro.by/yuridicheskie-lica/lizing-avtomobilej">на автомобили </a>,
                                             <a href="https://pro.by/yuridicheskie-lica/lizing-elektromobili"> на электромобили</a>, на официальном сайте лизинговой компании <a href="https://pro.by">Proleasing</a>.
                                            </span>
                                        </div>
                                    </div>
                                </stack>
                            </div>

                            {/*MT банк*/}
                            <div className="my-5">
                                <div className="mb-2">
                                    <img src="images/bank/mtbank.jpg" alt="МТ банк" style={{height: "25px"}}/>
                                </div>
                                <stack gap="{3}">
                                    <div className="mt-3 ms-4">
                                        <div><h5>Кредит «Большие планы»</h5></div>
                                        <div>
                                            <ul>
                                                <li>Ставка: в месяц заключения договора и в следующих 2 календарных месяцах
                                                    3%,
                                                    со следующего месяца 16,611%
                                                </li>
                                                <li>Срок: до 7 лет</li>
                                                <li>Кредит наличными - до 30 000 руб., безналичный кредит - до 50 000 руб.
                                                </li>
                                                <li>Выдача: по кредиту наличными - со следующего дня после подачи заявки, по
                                                    безналичному кредиту возможна в день подачи заявки
                                                </li>
                                                <li>Без справки о доходах, залога и поручителей</li>
                                                <li>Досрочное погашение: без штрафов и комиссий</li>
                                                <li>Без скрытых комиссий – только ставка</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}} href="https://www.mtbank.by/credits/bolshie-plany">подробнее
                                                о кредите на официальном сайте МТ банка</a>
                                        </div>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <div><h5>Автолизинг для физических лиц</h5></div>
                                        <div>
                                            <ul>
                                                <li>Фиксированная процентная ставка в белорусских рублях</li>
                                                <li>Обязательное страхование</li>
                                                <li>Без справки о доходах</li>
                                                <li>Без залога и поручительства</li>
                                                <li>Быстрое рассмотрение</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}} href="https://www.mtbank.by/leasing/private-person">подробнее
                                                о лизинге на официальном сайте МТ банка</a>
                                        </div>
                                    </div>
                                </stack>
                            </div>
                            {/*Сбербанк*/}
                            <div className="my-5">
                                <div className="mb-2">
                                    <img src="images/bank/sberbank.jpeg" alt="Сбербанк" style={{height: "30px"}}/>
                                </div>
                                <stack gap="{3}">
                                    <div className="mt-3 ms-4">
                                        <div><h5>Кредит «Купи авто в Online»</h5></div>
                                        <div>
                                            <ul>
                                                <li>Без первоначального взноса</li>
                                                <li>Процентная ставка от 15,9%</li>
                                                <li>Максимальная сумма до 65&nbsp;000 руб.</li>
                                                <li>Срок: до 10 лет</li>
                                                <li>Кредит до 100% стоимости автомобиля</li>
                                                <li>Деньги перечисляются на счет продавца</li>
                                                <li>Кредитуем новые и б/у автомобили</li>
                                                <li>Без залога, без КАСКО</li>
                                                <li>Возможность оформления кредита онлайн</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://www.sber-bank.by/credit-potreb/buy-car/conditions">подробнее о
                                                кредите на официальном сайте Сбербанка</a>
                                        </div>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <div><h5>Автокредит «Заведи и поехали»</h5></div>
                                        <div>
                                            <ul>
                                                <li>Процентная ставка от 3,9%</li>
                                                <li>Максимальная сумма до 300 000 руб.</li>
                                                <li>Срок кредита до 10 лет</li>
                                                <li>Без справок о доходах</li>
                                                <li>Возможность полного или частичного досрочного погашени</li>
                                                <li>Срок рассмотрения заявки 30 минут</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://www.sber-bank.by/credit-auto/zavedi-i-poehali/conditions">подробнее
                                                об автокредите на официальном сайте Сбербанка</a>
                                        </div>
                                    </div>
                                </stack>
                            </div>
                            {/*Агропромбанк*/}
                            <div className="my-5">
                                <div className="mb-2">
                                    <img src="images/bank/agroprombank.jpg" alt="Белагропромбанк" style={{height: "80px"}}/>
                                </div>
                                <stack gap="{3}">
                                    <div className="mt-3 ms-4">
                                        <div><h5>Кредит на автомобиль, электромобиль</h5></div>
                                        <div>
                                            <ul>
                                                <li>Процентная ставка от 10,5%</li>
                                                <li>Максимальная сумма до 90% или 100% стоимости автомобиля</li>
                                                <li>Срок кредитования до 10 лет</li>
                                                <li>Срок рассмотрения пакета документов 3-5 рабочих дней</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://www.belapb.by/chastnomu-klientu/kredity/kredit-na-avtomobil">подробнее
                                                о кредите на официальном сайте Белагропромбанка</a>
                                        </div>
                                    </div>
                                </stack>
                            </div>
                            {/*Дабрабыт*/}
                            <div className="my-5">
                                <div className="mb-2">
                                    <img src="images/bank/dabrabyt.jpg" alt="Банк Дабрабыт" style={{height: "30px"}}/>
                                </div>
                                <stack gap="{3}">
                                    <div className="mt-3 ms-4">
                                        <div><h5>Кредит «Больше чем на личное»</h5></div>
                                        <div>
                                            <ul>
                                                <li>Процентная ставка от 16,63%</li>
                                                <li>Срок кредитования до 10 лет</li>
                                                <li>Сумма кредита от 10 001 руб. до 50 000 руб. без поручителей</li>
                                                <li>Без справки о доходах до 50 000 руб.</li>
                                                <li>Срок кредита от 13 до 120 месяцев</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://bankdabrabyt.by/personal/credit/kredit-bolshe-chem-na-lichnoe">подробнее
                                                о кредите «Больше чем на личное» на официальном сайте банка Дабрабыт</a>
                                        </div>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <div><h5>Кредит «Мы подождём»</h5></div>
                                        <div>
                                            <ul>
                                                <li>Процентная ставка от 16,63% (фиксированная ставка)</li>
                                                <li>Срок кредитования до 6 лет</li>
                                                <li>Сумма кредита от 1000 руб. до 150 000 руб.</li>
                                                <li>Срок кредитования от 13 до 72 месяцев</li>
                                                <li>Отсрочка платежа по основному долгу на 6 или 12 месяцев</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://bankdabrabyt.by/personal/credit/kredit-my-podozhdem-">подробнее
                                                о кредите «Мы подождём» на официальном сайте банка Дабрабыт</a>
                                        </div>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <div><h5>Кредит «На личное»</h5></div>
                                        <div>
                                            <ul>
                                                <li>Процентная ставка от 19,99%</li>
                                                <li>Срок кредитования до 5 лет</li>
                                                <li>Без справки о доходах до 50 000 руб.</li>
                                                <li>Срок кредита от 6 до 60 месяцев</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://bankdabrabyt.by/personal/credit/potrebitelskiy-kredit-nalichnoe">подробнее
                                                о кредите «На личное» на официальном сайте банка Дабрабыт</a>
                                        </div>
                                    </div>
                                </stack>
                            </div>
                            {/*Сберлизинг*/}
                            <div className="my-5">
                                <div className="mb-2">
                                    <img src="images/bank/sberleasing.jpg" alt="Сберлизинг" style={{height: "25px"}}/>
                                </div>
                                <stack gap="{3}">
                                    <div className="mt-3 ms-4">
                                        <div><h5>Автомобили в лизинг</h5></div>
                                        <div>
                                            <ul>
                                                <li>Срок лизинга до 7 лет</li>
                                                <li>Авансовый платеж от 20%</li>
                                                <li>Валюта финансирования белорусские рубли и российские рубли</li>
                                                <li>Оплата лизинговых платежей в белорусских рублях</li>
                                                <li>Автомобили новые и с пробегом не старше 20 лет на конец срока лизинга
                                                </li>
                                                <li>График лизинговых платежей равномерный (аннуитет), убывающий</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://sberleasing.by/fizikam/avtolizing-legkovoy-avtotransport-fiz/?utm_source=google&amp;utm_medium=cpc&amp;utm_content=avto_10years&amp;utm_term=autotargeting&amp;utm_campaign=Fiz_Yur_Performance_Max_Obl_goroda&amp;gad_source=1&amp;gclid=Cj0KCQjw8pKxBhD_ARIsAPrG45lG9gbt56CtC6VSrjx0V2cPXLqbjQmOEOq4ZLFH9Md6QwTRw1ZBytQaAhYcEALw_wcB">подробнее
                                                о лизинге на официальном сайте Сберлизинга</a>
                                        </div>
                                    </div>
                                </stack>
                            </div>
                            {/*Промагролизинг*/}
                            <div className="my-5">
                                <div className="mb-2">
                                    <img src="images/bank/promagroleasing.jpg" alt="Промагролизинг" style={{height: "50px"}}/>
                                </div>
                                <stack gap="{3}">
                                    <div className="mt-3 ms-4">
                                        <div><h5>Лизинговая программа «Лидер дорог»</h5></div>
                                        <div>
                                            <ul>
                                                <li>Предмет лизинга легковые автомобили</li>
                                                <li>Срок лизинга от 13 до 120 месяцев</li>
                                                <li>Ставка рефинансирования НБ РБ, увеличенная не менее чем на 2,5 п.п.</li>
                                                <li>Авансовый платёж от 10% от стоимости авто с учётом НДС</li>
                                                <li>Обеспечение: задаток и/или не менее одного платёжеспособного поручителя;
                                                    возможно заключение договора без обеспечения
                                                </li>
                                                <li>Выкупная стоимость 1% стоимости авто с учётом НДС</li>
                                                <li>Возможен досрочный выкуп</li>
                                                <li>Страхование АВТОКАСКО</li>
                                                <li>Ежемесячная периодичность уплаты лизинговых платежей</li>
                                                <li>Возможность включения в совокупный доход лизингополучателя дохода
                                                    поручителя (не более двух)
                                                </li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://pal.by/programmy-lizinga/lizingovaa-programma-lider#auto">подробнее
                                                о лизинге «Лидер дорог» на официальном сайте Промагролизинга</a>
                                        </div>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <div><h5>Лизинговая программа «Заряжай и езжай»</h5></div>
                                        <div>
                                            <ul>
                                                <li>Предмет лизинга электромобили</li>
                                                <li>Срок лизинга для авто в пробегом до 60 месяцев; для новых авто до 120
                                                    месяцев
                                                </li>
                                                <li>Ставка по лизингу первые 12 мес. - ставка рефинансирования НБ РБ. С 13
                                                    по 60/120 мес. - ставка рефинансирования НБ РБ, увеличенная не менее чем
                                                    на 3 п.п.
                                                </li>
                                                <li>Авансовый платёж от 10% до 40% от стоимости авто с учётом НДС</li>
                                                <li>Обеспечение: задаток и/или не менее одного платёжеспособного поручителя;
                                                    возможно заключение договора без обеспечения
                                                </li>
                                                <li>Выкупная стоимость 1% стоимости авто с учётом НДС</li>
                                                <li>Возможен досрочный выкуп, но не ранее чем через 13 месяцев с пересчётом
                                                    вознаграждения Лизингодателя, исходя из фактического срока пользования
                                                </li>
                                                <li>Страхование по системе АВТОКАСКО</li>
                                                <li>Ежемесячная периодичность уплаты лизинговых платежей</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://pal.by/programmy-lizinga/lizingovaa-programma-zarazaj#auto">подробнее
                                                о лизинге «Заряжай и езжай» на официальном сайте Промагролизинга</a>
                                        </div>
                                    </div>
                                </stack>
                            </div>
                            {/*А лизинг*/}
                            <div className="my-5">
                                <div className="mb-2">
                                    <img src="images/bank/a-leasing.jpg" alt="А-Лизинг" style={{height: "65px"}}/>
                                </div>
                                <stack gap="{3}">
                                    <div className="mt-3 ms-4">
                                        <div><h5>Автолизинг</h5></div>
                                        <div>
                                            <ul>
                                                <li>Новые авто и авто с пробегом</li>
                                                <li>Авансовый платёж от 20%</li>
                                                <li>Срок лизинга от 12 до 84 месяцев</li>
                                                <li>Валюты: BYN, USD, EUR, RUB</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://a-leasing.by/?utm_source=google&amp;utm_medium=cpc&amp;utm_campaign=0_avans&amp;utm_content=search&amp;gad_source=1&amp;gclid=Cj0KCQjw8pKxBhD_ARIsAPrG45nvDcs9Ro24YIxrCHIPurko5j3gDtcRQJOeAdabzvfVWRHlSPYSkmEaAtv2EALw_wcB">подробнее
                                                о лизинге на официальном сайте А-Лизанга</a>
                                        </div>
                                    </div>
                                </stack>
                            </div>
                            {/*General лизинг*/}
                            <div className="my-5">
                                <div className="mb-2">
                                    <img src="images/bank/general-leasing.jpg" alt="General Leasing" style={{height: "30px"}}/>
                                </div>
                                <stack gap="{3}">
                                    <div className="mt-3 ms-4">
                                        <div><h5>Лизинг новых и б/у автомобилей для физических лиц и ИП</h5></div>
                                        <div>
                                            <ul>
                                                <li>Любая сумма финансирования</li>
                                                <li>Авто любого года выпуска и модели</li>
                                                <li>Без поручителей, залогов и справках о доходе</li>
                                                <li>Срок одобрения и заключение сделки от 2-х часов</li>
                                                <li>Возможность отсрочки платежа, если возникли сложности с очередным
                                                    взносом
                                                </li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://generalleasing.by/?utm_source=google&amp;utm_medium=cpc&amp;utm_campaign=brendovye&amp;utm_content=682813988046&amp;utm_term=%D0%B4%D0%B6%D0%B5%D0%BD%D0%B5%D1%80%D0%B0%D0%BB%20%D0%BB%D0%B8%D0%B7%D0%B8%D0%BD%D0%B3&amp;gad_source=1&amp;gclid=Cj0KCQjw8pKxBhD_ARIsAPrG45kwOS4G9PcL1rDsOpqr5v8VY9xmRltZzrWZnsWSk3_da8EjW1Cqzg8aAiDKEALw_wcB">подробнее
                                                о лизинге на официальном сайте General Leasing</a>
                                        </div>
                                    </div>
                                </stack>
                            </div>
                            {/*Micro лизинг*/}
                            <div className="my-5">
                                <div className="mb-2">
                                    <img src="images/bank/microleasing.jpg" alt="Микролизинг" style={{height: "25px"}}/>
                                </div>
                                <stack gap="{3}">
                                    <div className="mt-3 ms-4">
                                        <div><h5>Лизинг новых и б/у автомобилей для ИП и юридических лиц</h5></div>
                                        <div>
                                            <ul>
                                                <li>Валюта договора: BYN, USD, EUR, RUB</li>
                                                <li>Срок рассмотрения заявки 1 день</li>
                                                <li>Аванс: от 10% до 39% стоимости авто</li>
                                                <li>Срок лизинга: от 1 года до 7 лет</li>
                                                <li>Лизинговые платежи: равные, убывающие, индивидуальный график</li>
                                                <li>Без КАСКО: авто стоимостью до 20 000 EUR, старше 5 лет</li>
                                                <li>Автомобиль для такси без КАСКО при авансе от 30%</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://mikro-leasing.by/yuridicheskim-licam/lizing-legkovyh-avtomobilej">подробнее
                                                о лизинге для ИП и юридических лиц на официальном сайте Микролизинга</a>
                                        </div>
                                    </div>
                                    <div className="mt-3 ms-4">
                                        <div><h5>Лизинг новых и б/у автомобилей для физических лиц</h5></div>
                                        <div>
                                            <ul>
                                                <li>Стоимость авто от 2 000 USD</li>
                                                <li>Срок лизинга: от 13 до 84 месяцев</li>
                                                <li>Аванс от 10% до 39% стоимости автомобиля</li>
                                                <li>Валюта договора: BYN, USD, EUR, RUB</li>
                                                <li>Валюта платежа: белорусские рубли по курсу НБ РБ на дату оплаты</li>
                                                <li>Без КАСКО: авто стоимостью до 30 000 EUR, старше 5 лет</li>
                                            </ul>
                                            <a style={{fontSize: "0.8em"}}
                                               href="https://mikro-leasing.by/dlja-fizicheskih-lic">подробнее о лизинге для
                                                физических лиц на официальном сайте Микролизинга</a>
                                        </div>
                                    </div>
                                </stack>
                            </div>
                        </stack>
                    </div>
                    <div className="col-lg-4 order-lg-2">
                        <RelevantLinkBlock relevantUrls={relevantUrls}/>
                    </div>

                </div>
            </div>
        </div>

    );

}