import React from "react";
import OffCanvasSidebar from "./offcanvas/OffCanvasSidebar";
import OffCanvasPhoneSidebar from "./offcanvas/OffCanvasPhoneSidebar";
import {AppBar, Container, Stack, Toolbar} from "@mui/material";
import Link from '@mui/material/Link';
import Box from "@mui/material/Box";

export default function Head() {

    return (

       <Box sx={{ flexGrow: 1 }}>
            <AppBar sx={{ top: 0, bottom: 'auto' }}  color="transparent" elevation={0}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Link href="/" sx={{ flexGrow: 1 }}>
                            <img className="logo" src="/logo-white.PNG" alt="Логотип БелАвтоЭксперт"
                                 title="БелАвтоЭксперт" style={{marginLeft: "-15px"}}/>
                        </Link>

                        <Stack direction="column"
                               alignItems="flex-end"
                               spacing={2}>
                            <OffCanvasPhoneSidebar/>
                            <OffCanvasSidebar/>
                        </Stack>

                </Toolbar>
                </Container>
            </AppBar>

       </Box>
    );
}