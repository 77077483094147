import {useLocation, useSearchParams} from "react-router-dom";
import {useEffect} from "react";

export const replaceJSX = (subject, find, replace) => {
    let result = [];
    if(Array.isArray(subject)){
        for(let part of subject)
            result = [ ...result, replaceJSX(part, find, replace) ]
        return result;
    }else if(typeof subject !== 'string')
        return subject;
    let parts = subject.split(find);
    for(let i = 0; i < parts.length; i++) {
        result.push(parts[i]);
        if((i+1) !== parts.length)
            result.push(replace);
    }
    return result;
}

export const replaceJSXRecursive = (subject, replacements) => {
    for(let key in replacements){
        subject = replaceJSX(subject, key, replacements[key])
    }
    return subject;
}

export function useUrlId() {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    return {id};
}

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }, [pathname]);

    return null;
}
