import React, {Fragment, useEffect} from "react";
import ReactGA from "react-ga4";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import {breadcrumbNameMap, servicePriceAC, servicePriceDisk} from "../Constants";
import SectionHeading from "../SectionHeading";
import RelevantLinkBlock from "../fragments/RelevantLinkBlock";

export default function PriceTable({columnNames, priceData}) {

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 400}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {columnNames.map(columnName => {
                            return (<TableCell align="left">{columnName}</TableCell>)
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {priceData.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            {Object.values(row).slice(1).map(e => <TableCell align="left">{e}</TableCell>)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    );

}