import React, {Fragment, useEffect} from "react";
import ReactGA from "react-ga4";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import {breadcrumbNameMap, servicePriceAC, servicePriceDisk} from "../Constants";
import SectionHeading from "../SectionHeading";
import RelevantLinkBlock from "../fragments/RelevantLinkBlock";
import PriceTable from "./PriceTable";

export default function TirePrice() {

    let relevantUrls = ["/service", "/service/servicePrice"];

    useEffect(() => {

        document.title = 'Прайс на услуги шиномонтажа и сервисного хранения шин';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta=document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Прайс на сервиисные услуги Тимирязева 119/1');

        let keywordsMeta=document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content", 'Прайс на сервиисные услуги Тимирязева 119/1');

    }, []);

    function createTireFitData(name, size1, size2, size3, size4, size5) {
        return { name, size1, size2, size3, size4, size5 };
    }

    function tireFitColumnNames() {
        return ["Услуга", "R12, R13 (руб.)", "R14 - R16 (руб.)", "R17 - R19 (руб.)", "R20 - R21 (руб.)", "Более R21 (руб.)"];
    }

    function createTireStoreData(name, price) {
        return { name, price };
    }

    function tireStoreColumnNames() {
        return ["Услуга", "Стоимость"];
    }

    const tireStorePriceData = [
        createTireFitData('Комплект шин без дисков', '13 рублей в месяц'),
        createTireFitData('Хранение комплекта покрышек с дисками', '20 рублей в месяц'),
        createTireFitData('Подготовка шин к хранению', '5 рублей за штуку')];

    const tireFitPriceData = [
        createTireFitData('Шиномонтаж 4-х колес без стоимости грузов (снятие и установка колеса + замена шины с балансировкой)',
            '65.00', '75.00', '95.00', '110.00', '130.00'),
        createTireFitData('Шиномонтаж 1-го колеса без стоимости грузов (снятие и установка колеса + замена шины с балансировкой)',
            '16.00', '18.00', '23.00', '27.00', '31.00'),
        createTireFitData('Балансировка колеса (без стоимости грузов)',
            '6.00', '7.50', '9.00', '10.00', '11.00'),
        createTireFitData('Стоимость грузов (стоимость зависит от количества грузов, необходимых для балансировки шин)',
            'От 2.00 до 15.00', 'От 2.00 до 15.00', 'От 2.00 до 15.00', 'От 2.00 до 15.00', 'От 2.00 до 15.00')
    ];

    const tireFitTrackPriceData = [
        createTireFitData('Шиномонтаж 4-х колес без стоимости грузов (снятие и установка колеса + замена шины с балансировкой)',
            '90.00', '98.00', '110.00', '124.00', '144.00'),
        createTireFitData('Шиномонтаж 1-го колеса без стоимости грузов (снятие и установка колеса + замена шины с балансировкой)',
            '22.50', '24.50', '27.50', '31.00', '36.00'),
        createTireFitData('Балансировка колеса (без стоимости грузов)',
            '8.00', '9.00', '10.00', '11.00', '13.00'),
        createTireFitData('Ремонт одного повреждения бескамерной шины (без стоимости латок)',
            '9.00', '9.00', '9.00', '10.00', '10.00'),
        createTireFitData('Ремонт камеры шины автомобиля (одно повреждение)',
            '6.50', '6.50', '8.00', '8.00', '8.00')
    ];

    return (
        <div className="site-section">
            <div className="container">

                <Grid container spacing={0}>
                    <Grid xs={12} md={8} lg={9}>
                        <SectionHeading sectionHeadingTitle={"Прайс на сервисные услуги"}
                                        sectionHeadingMessage={<>Предлагаем Вам качественные услуги по приемлемым ценам.</>}/>

                        <PriceTitle>Шиномонтаж легковых автомобилей</PriceTitle>
                        <PriceTable columnNames={tireFitColumnNames()} priceData={tireFitPriceData}/>

                        <PriceTitle>Шиномонтаж (джипы, кроссоверы и микроавтобусы)</PriceTitle>
                        <PriceTable columnNames={tireFitColumnNames()} priceData={tireFitTrackPriceData}/>

                        <PriceTitle>Хранение шин</PriceTitle>
                        <PriceTable columnNames={tireStoreColumnNames()} priceData={tireStorePriceData}/>

                    </Grid>
                    <Grid xs={12} md={4} lg={3} >
                        <RelevantLinkBlock relevantUrls={relevantUrls}/>
                    </Grid>
                </Grid>

            </div>
        </div>

    );

    function PriceTitle({children}) {
        return <h3 className="mt-5 mb-3">{children}</h3>
    }

}