import React, {useEffect} from "react";
import {Link} from "react-router-dom";

import {breadcrumbNameMap, exchangeRate} from "../Constants";
import Badge from 'react-bootstrap/Badge';
import {theme} from "../MuiCustomization";
import {ThemeProvider} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import VehicleListItem from "../vehicle/VehicleListItem";

export default function RelevantLinkBlock({relevantUrls}) {

    return <div className="row">
            <div className="col ml-auto">
                <div className="bg-white p-3 p-md-5">
                    <h4 className="text-black mb-4">Релевантные ссылки</h4>

                    {relevantUrls.map(url => (
                        <ListItem disableGutters={true}>
                            <ListItemButton component="a" href={url} color="primary" disableGutters={true}>
                                <ListItemText
                                    primary={breadcrumbNameMap[url].title}
                                    secondary={breadcrumbNameMap[url].description}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}

                </div>
            </div>
        </div>;
}