import React, {useEffect} from "react";
import ReactGA from "react-ga4";

export default function CommisssionQuestions() {

    useEffect(() => {

        document.title = 'Ответы на вопросы по помощи в продаже авто в автосалоне';
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        let descMeta=document.querySelector("meta[name='description']");
        descMeta.setAttribute("content", 'Ответы на вопросы по помощи в продаже авто в автосалоне');

        let keywordsMeta=document.querySelector("meta[name='keywords']");
        keywordsMeta.setAttribute("content", 'Ответы на вопросы по помощи в продаже авто в автосалоне');

    }, []);

    return (
        <div className="site-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <h2 className="section-heading"><strong>Вопросы и ответы</strong></h2>
                        <p className="mb-5">Здесь собраны ответы на самые частозадаваемые нам вопросы.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 mb-4">
                        <div className="testimonial-2">
                            <blockquote className="mb-4">
                                <p><span className="icon-question-circle" style={{color: "#dc1a22"}}></span> "Каковы условия
                                    продажи авто в кредит?"</p>
                            </blockquote>
                            <blockquote><span className="icon-question_answer" style={{color: "#dc1a22"}}></span> "Авто
                                можно купить как за наличные деньги,
                                так и в кредит/лизинг. Можем предоставить вам своих партнеров,
                                или же Вы можете воспользоваться услугами любой финансовой организации по своему
                                усмотрению."
                            </blockquote>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="testimonial-2">
                            <blockquote className="mb-4">
                                <p><span className="icon-question-circle" style={{color: "#dc1a22"}}></span> "Какие
                                    документы необходимы для оформления договора комиссии?"</p>
                            </blockquote>
                            <blockquote><span className="icon-question_answer" style={{color: "#dc1a22"}}></span> "Для
                                оформления договора необходимы: общегражданский паспорт либо вид на жительство и
                                свидетельство о регистрации автомашины."
                            </blockquote>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="testimonial-2">
                            <blockquote className="mb-4">
                                <p><span className="icon-question-circle" style={{color: "#dc1a22"}}></span> "Необходимо ли
                                    владельцу авто лично встречаться с покупателем?"</p>
                            </blockquote>
                            <blockquote><span className="icon-question_answer" style={{color: "#dc1a22"}}></span> "В личной
                                встрече владельца авто и покупателя нет необходимости, т.к. мы передаем покупателю
                                автомашины все документы, необходимые для постановки авто на учет."
                            </blockquote>
                        </div>
                    </div>

                    <div className="col-lg-4 mb-4">
                        <div className="testimonial-2">
                            <blockquote className="mb-4">
                                <p><span className="icon-question-circle" style={{color: "#dc1a22"}}></span> "Какие
                                    документы необходимы для постановки автомашины на учет?"</p>
                            </blockquote>
                            <blockquote><span className="icon-question_answer" style={{color: "#dc1a22"}}></span> "Для
                                постановки автомашины на учет необходимы: Договор комиссии, договор купли-продажи и акты
                                приема-передачи автомашины."
                            </blockquote>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="testimonial-2">
                            <blockquote className="mb-4">
                                <p><span className="icon-question-circle" style={{color: "#dc1a22"}}></span> "Каким образом
                                    оплачиваются услуги по предпродажной подготовке авто?"</p>
                            </blockquote>
                            <blockquote><span className="icon-question_answer" style={{color: "#dc1a22"}}></span> "Услуги по
                                предпродажной подготовке авто (мойка, чистка салона) предоставляются бесплатно при
                                условии нахождения автомашины на стоянке автосалона в течение всего срока договора
                                комиссии."
                            </blockquote>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="testimonial-2">
                            <blockquote className="mb-4">
                                <p><span className="icon-question-circle" style={{color: "#dc1a22"}}></span> "Каким образом
                                    охраняется автостоянка?"</p>
                            </blockquote>
                            <blockquote><span className="icon-question_answer" style={{color: "#dc1a22"}}></span> "Стоянка
                                автосалона охраняется круглосуточно, в том числе посредством видеонаблюдения. При
                                необходимости мы можем в любой момент выслать скриншот с изображением Вашего авто на
                                стоянке автосалона."
                            </blockquote>
                        </div>
                    </div>

                    <div className="col-lg-4 mb-4">
                        <div className="testimonial-2">
                            <blockquote className="mb-4">
                                <p><span className="icon-question-circle" style={{color: "#dc1a22"}}></span> "Каким образом
                                    Вы рекламируете автомашину для продажи?"</p>
                            </blockquote>
                            <blockquote><span className="icon-question_answer" style={{color: "#dc1a22"}}></span> "Фото,
                                видео и описание автомашины размещается на всех доступных ресурсах сети интернет (av.by,
                                abw.by, onliner.by, auto.ru, avito.ru и другие), на нашей страничке в Инстаграмме.
                                Также, мы размещаем баннеры с рекламой на нашей автоплощадке, делая ее более заметной
                                для потенциальных покупателей."
                            </blockquote>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="testimonial-2">
                            <blockquote className="mb-4">
                                <p><span className="icon-question-circle" style={{color: "#dc1a22"}}></span> "Возможно ли
                                    оформить договор комиссии удаленно?"</p>
                            </blockquote>
                            <blockquote><span className="icon-question_answer" style={{color: "#dc1a22"}}></span> "Для
                                оформления договора комиссии необходимо личное присутствие в нашем офисе. Также можно
                                доверить оформление третьему лицу, при этом обязательно необходимо воспользоваться
                                услугами нотариуса."
                            </blockquote>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    );

}