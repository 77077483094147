import React from "react";

export default function CallNowBlock() {
    return (
        <div className="site-section py-5" style={{backgroundColor: '#364d59'}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-9 mb-4 mb-md-0">
                        <h2 className="mb-0 text-white">У вас есть вопросы? </h2>
                        <p className="mb-0 opa-7">Будем рады рассказать обо всех интересующих Вас подробностях. </p>
                    </div>
                    <div className="col-lg-3 text-md-right">
                        <a href="tel:+375291888969" className="btn btn-white p-3">Позвонить сейчас</a>
                    </div>
                </div>
            </div>
        </div>
    );
}