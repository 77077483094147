import React from "react";
import {Breadcrumbs, Typography} from "@mui/material";
import {
    Link as RouterLink,
    useLocation,
} from 'react-router-dom';
import Link from "@mui/material/Link";
import {breadcrumbNameMap} from "./Constants";

export default function PageBreadcrumbs() {

    const location = useLocation();
    const pathnameList = location.pathname.split('/').filter((x) => x);

    function LinkRouter(props) {
        return <Link {...props} component={RouterLink}/>;
    }

    return (
        (pathnameList && pathnameList.length > 0) &&
        <div className="hero inner-page">
            <div className="container">
                <div className="row align-items-end">

                    <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: 1 }}>
                        <LinkRouter underline="hover" color="inherit" to="/">
                            Главная
                        </LinkRouter>
                        {pathnameList.map((value, index) => {
                            const last = index === pathnameList.length - 1;
                            const to = `/${pathnameList.slice(0, index + 1).join('/')}`;

                            return last ? (
                                <Typography color="text.primary" key={to}>
                                    {breadcrumbNameMap[to].title}
                                </Typography>
                            ) : (
                                <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                                    {breadcrumbNameMap[to].title}
                                </LinkRouter>
                            );
                        })}
                    </Breadcrumbs>

                </div>
            </div>
        </div>
    );
}