import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import {theme} from "../MuiCustomization";
import {SwipeableDrawer, ThemeProvider} from "@mui/material";
import ContactPhoneRoundedIcon from '@mui/icons-material/ContactPhoneRounded';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import ContactsFragment from "../ContactsFragment";
import {grey} from "@mui/material/colors";
import {ContactPhoneOutlined} from "@mui/icons-material";

export default function OffCanvasPhoneSidebar() {
    const [sidePhoneBarOpen, setSidePhoneBarOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setSidePhoneBarOpen(newOpen);
    };

    const DrawerList = (
        <Box sx={{width: 300}} role="presentation" onClick={toggleDrawer(true)}>
            <ContactsFragment/>
        </Box>
    )

    return (
        <div>

            <Button variant="contained" style={{color: "black", backgroundColor: grey[50], border: "solid 0.5px #b71c1c" }} onClick={toggleDrawer(true)}
                    startIcon={<ContactPhoneOutlinedIcon color="secondary" />}>
                Контакты
            </Button>
            <SwipeableDrawer open={sidePhoneBarOpen} onClose={toggleDrawer(false)}>
                {DrawerList}
            </SwipeableDrawer>

        </div>
    );
}