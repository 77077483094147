import React from "react";

export default function AddressFragment() {

    return (
        <>
            <span>ул. Тимирязева 119, корпус 1</span>
            <div>

                <iframe
                    src="https://yandex.by/map-widget/v1/org/belavtoekspert/64844556540/?ll=27.465532%2C53.931451&amp;source=constructorLink&amp;um=constructor%3A1c2d6dca7592d2ce04222c4a88b593ed38a27bfa8467ca7bb755762e7ac9c752&amp;utm_source=share&amp;z=16"
                    width="100%" allowFullScreen="true" style={{position:"relative"}}></iframe>

                <img src="/building.JPEG" alt="Наш офис" className="img-fluid rounded"></img>

                <a href="yandexnavi://build_route_on_map?lat_to=53.931451&amp;lon_to=27.465532"
                   className="btn btn-red mt-2">
                    <span className="icon-navigation"></span> Построить маршрут в Яндекс навигаторе
                </a>
            </div>
        </>

    );

}